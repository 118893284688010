import { create } from "zustand";

const useAgentsZus = create((set) => ({
  loadingAgent: false,
  setLoadingAgent: (loadingAgent) => set({ loadingAgent }),

  agents: [],
  setAgents: (agents) => set({ agents }),
}));

export default useAgentsZus;
