import React from "react";
import { FaListUl } from "react-icons/fa6";

const Listmessage = ({ listData }) => {
  const RadioList = listData?.sections[0]?.rows  || [] ;

  return (
    <div>
      <div className="flex flex-col max-w-md p-2 bg-[#005C58] rounded-lg">
        <div className="text-white text-sm">{listData?.bodyText}</div>
        <div
          className="flex gap-4 justify-center items-center border-gray-500 "
          onClick={() => document.getElementById("my_modal_3").showModal()}
        >
          <span className="text-xl text-white">
            <FaListUl className="text-sm" />
          </span>
          <span className="text-white ">{listData?.buttonText}</span>
          <dialog id="my_modal_3" className="modal">
            <div className="modal-box  pt-0 pr-0 pl-0 bg-white">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                  ✕
                </button>
              </form>
              <div className="font-bold w-full bg-green-600 text-lg h-14 flex items-center ">
                <span className="p-3  text-white">{listData?.buttonText}</span>
              </div>
              <div>
                {RadioList.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between  mt-4 mb-2 "
                  >
                    <label className="text-lg p-4 text-green-700">
                      {item. title}
                    </label>
                    {/* <input type="radio" name="belt" className="mr-2 mt-4" 
                  /> */}
                    <input
                      type="radio"
                      name="radio-5"
                      className="radio radio-success mr-2 mt-4"
                    />
                  </div>
                ))}
              </div>
            </div>
          </dialog>
        </div>
      </div>
    </div>
  );
};

export default Listmessage;
