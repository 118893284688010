// //STARTER CODE OF THIS FILE
// import React, { useEffect, useRef } from "react";
// import Messages from "./Messages";
// import MessageInput from "./MessageInput";
// import useZusConversation from "../../../zustand/useZusConversation";
// import { UseAuthContext } from "../../../context/AuthContext";
// import { TiMessages } from "react-icons/ti";
// import TemplateMsgSend from "./TemplateMsgSend";
// import Intervened from "./Intervened";

// const MessageContainer = () => {
//   const { selectedConversation, setSelectedConversation } =
//     useZusConversation();
//   const { authUser } = UseAuthContext();

//   useEffect(() => {
//     //cleanup function (unmounts)
//     return () => setSelectedConversation(null);
//   }, [setSelectedConversation]);

//   //Check is chat  expired? code start
//   const now = Date.now(); // Current timestamp in milliseconds
//   const expiresAt = new Date(selectedConversation?.expiresAt).getTime(); // Convert ISO string to timestamp in milliseconds
//   const isChatExpired = now > expiresAt;
//   if (isChatExpired) {
//     console.log("The expiration date has passed.");
//   } else {
//     console.log("The expiration date has not yet passed.");
//   }
//   //Check is chat  expired? code start

//   // Modal Ref
//   const modalRef = useRef(null);

//   const showModal = () => {
//     if (modalRef.current) {
//       modalRef.current.showModal();
//     }
//   };

//   return (
//     <div className="live-chat customScroller border-none w-2/4 flex flex-col">
//       {selectedConversation ? (
//         <>
//           {/* HEADER */}
//           <div className="bg-[#0A474C] text-base px-4 py-4 mb-2">
//             <span className="label-text font-bold text-white">To : </span>
//             <span className="text-white font-bold me-2">
//               {selectedConversation.name}
//             </span>
//             <span className="text-gray-200 font-medium">
//               (+{selectedConversation.mobile_Number})
//             </span>
//           </div>
//           <Messages />
//           {/* {isChatExpired && <TemplateMsgSend />} */}
//           {/* {!isChatExpired && <MessageInput showModal={showModal} />} */}
//           {isChatExpired && <Intervened />}
//         </>
//       ) : (
//         <NoChatSelected name={authUser.fullName} />
//       )}
//     </div>
//   );
// };

// export default MessageContainer;

// const NoChatSelected = ({ name }) => {
//   return (
//     <div className="flex items-center justify-center w-full h-full">
//       <div className="px-4 text-center sm:text-lg md:text-xl text-[#0A474C] font-semibold flex flex-col items-center gap-2">
//         <p>Welcome 👋 {name} ❄️</p>
//         <p>Select a chat to start messaging</p>
//         <TiMessages className="text-3xl md:text-6xl text-center" />
//       </div>
//     </div>
//   );
// };

import React, { useEffect, useRef, useState } from "react";
import Messages from "./Messages";
import MessageInput from "./MessageInput";
import useZusConversation from "../../../zustand/useZusConversation";
import { UseAuthContext } from "../../../context/AuthContext";
import { TiMessages } from "react-icons/ti";
import TemplateMsgSend from "./TemplateMsgSend";
import Intervened from "./Intervened";
import {
  Button,
  Menu,
  MenuItem,
  TextField,
  Avatar,
  Typography,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import {
  Usegetagents,
  Useresolvebutton,
  UseTransferAgent,
} from "../../../hooks/useAgenst";
import useAgentsZus from "../../../zustand/useAgents.Zus";
import OverTake from "./OverTake";

const MessageContainer = () => {
  const { selectedConversation, setSelectedConversation } =
    useZusConversation();
  const { authUser } = UseAuthContext();

  const { agents: isAllagents } = useAgentsZus();

  const { istransferAgent, isloadingAgent, transferAgent } = UseTransferAgent();
  const { isresolved, isresolvedloader, updateresolvedata } =
    Useresolvebutton();
  console.log("is agents looks likes this:", isAllagents);
  useEffect(() => {
    // Cleanup function (unmounts)
    return () => setSelectedConversation(null);
  }, [setSelectedConversation]);

  // Check if chat is expired
  const now = Date.now(); // Current timestamp in milliseconds
  const expiresAt = new Date(selectedConversation?.expiresAt).getTime(); // Convert ISO string to timestamp in milliseconds
  const isChatExpired = now > expiresAt;

  // console.log("selectedConversation:", selectedConversation);

  // Modal Ref
  const modalRef = useRef(null);

  const showModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

  // Dropdown state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  //  const [iscontacted,setcontacted] =useState("");
  const [isAgentselected, setAgentselected] = useState("");
  // const selectedcontact = setSelectedConversation?._id || null

  console.log("selected agent  looks likes this :", isAgentselected);
  // console.log("selected number looks likes this :",iscontacted);

  // console.log("selecetd contacts looks likes this :", selectedConversation._id);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResolve = async () => {
    console.log("Resolve button clicked");

    // console.log("User selected:", user);
    // setAgentselected(user);
    let updatedata;
    if (selectedConversation._id) {
      updatedata = {
        contactId: selectedConversation._id,
        senderId: authUser?.agentId ? authUser.agentId : authUser._id,
      };
    }

    await updateresolvedata(updatedata);
  };

  // Dependency ensures the effect runs when setSelectedConversation changes

  // console.log(
  //   "selected contacts id looks likes:",
  //   selectedConversation?._id || null
  // );

  const filteredUsers = (isAllagents || [])?.filter((user) =>
    user.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectUser = async (user) => {
    console.log("User selected:", user);
    setAgentselected(user);
    let updatedata;
    if (selectedConversation._id) {
      updatedata = {
        contactId: selectedConversation._id,
        agentobjectId: user,
        senderId: authUser?.agentId ? authUser.agentId : authUser._id,
      };
    }

    await transferAgent(updatedata);

    handleClose();
  };

  const currentauthuser = authUser?.agnet_id
    ? authUser?.agnet_id
    : authUser?._id;

  // if (selectedConversation) {
  //   console.log(
  //     "test1:",
  //     currentauthuser,
  //     "/",
  //     selectedConversation.agentId,
  //     "/",
  //     authUser?.agnet_id
  //   );

  //   console.log("test2", selectedConversation.agentId === currentauthuser);
  //   // console.log("test3", selectedConversation.agentId , currentauthuser,currentauthuser);

  //   console.log(
  //     "test5:",
  //     !isChatExpired &&
  //       selectedConversation.agentId === currentauthuser &&
  //       !selectedConversation?.requesting
  //   );
  // }

  return (
    <div className="live-chat customScroller border-none w-2/4 flex flex-col">
      {selectedConversation ? (
        <>
          {/* HEADER */}
          <div className="bg-[#0A474C] text-base px-4 py-4 mb-2 flex items-center justify-between">
            <div>
              <span className="label-text font-bold text-white">To : </span>
              <span className="text-white font-bold me-2">
                {selectedConversation.name}
              </span>
              <span className="text-gray-200 font-medium">
                (+{selectedConversation.mobile_Number})
              </span>
            </div>
            {selectedConversation.agentId === currentauthuser && (
              <Box display="flex" gap={2}>
                {/* Transfer To Button */}
                <Button variant="contained" onClick={handleClick}>
                  Transfer To
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <Box p={2}>
                    <TextField
                      placeholder="Search by name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Box>
                  <List>
                    {filteredUsers.map((user, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleSelectUser(user?._id)}
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: user?.color }}>
                            {user.name[0]}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={user.name}
                          secondary={
                            <Typography
                              color={
                                user?.status === "online" ? "green" : "gray"
                              }
                            >
                              {user?.status}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Menu>

                {/* Resolve Button */}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleResolve}
                >
                  Resolve
                </Button>
              </Box>
            )}
          </div>
          <Messages />

          {!isChatExpired && selectedConversation.agentId === null && (
            <Intervened
              currentauthuser={currentauthuser}
              selectedContact={selectedConversation._id}
            />
          )}

          {isChatExpired && selectedConversation.agentId === null && (
            <TemplateMsgSend />
          )}

          {isChatExpired && selectedConversation.agentId === currentauthuser &&
            !selectedConversation?.requesting  && (
            <TemplateMsgSend />
          )}

          {selectedConversation.agentId &&
            selectedConversation.agentId !== currentauthuser &&
            !selectedConversation?.requesting && (
              <OverTake
                currentauthuser={currentauthuser}
                selectedContact={selectedConversation._id}
                authUser={authUser}
              />
            )}

          {!isChatExpired &&
            selectedConversation.agentId === currentauthuser &&
            !selectedConversation?.requesting && (
              <MessageInput showModal={showModal} />
            )}
        </>
      ) : (
        <NoChatSelected name={authUser.fullName} />
      )}
    </div>
  );
};

export default MessageContainer;

const NoChatSelected = ({ name }) => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="px-4 text-center sm:text-lg md:text-xl text-[#0A474C] font-semibold flex flex-col items-center gap-2">
        <p>Welcome 👋 {name} ❄️</p>
        <p>Select a chat to start messaging</p>
        <TiMessages className="text-3xl md:text-6xl text-center" />
      </div>
    </div>
  );
};
