import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, TextField, Select, MenuItem } from "@mui/material";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
} from "recharts";
import { UseAnalytics } from "../../hooks/useAnalytics";

// Sample Data
const initialData = [
  { date: "30 Nov", user: 54, business: 474, chatbot: 18 },
  { date: "01 Dec", user: 150, business: 10, chatbot: 50 },
  { date: "02 Dec", user: 300, business: 600, chatbot: 100 },
  { date: "03 Dec", user: 50, business: 0, chatbot: 30 },
];

const AnalyticsChart = ({ data ,total }) => (
  <Box>
    <div className="w-full flex justify-around mt-4">
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        user   {total?.user}
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        business {total?.business}
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Chat bot {total?.chatbot}
      </Typography>
    </div>
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data}>
        {/* Gradient for Smooth Background */}
        <defs>
          <linearGradient id="colorUser" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#8884d8" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#8884d8" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="colorBusiness" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#BEC001" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#BEC001" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="colorChatbot" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#ffc658" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#ffc658" stopOpacity={0.1} />
          </linearGradient>
        </defs>

        {/* Grid and Axis */}
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis domain={["auto", "auto"]} />
        <Tooltip />

        {/* Area Chart Lines */}
        <Area
          type="monotone"
          dataKey="user"
          stroke="#8884d8"
          strokeWidth={2.5}
          fill="url(#colorUser)"
        />
        <Area
          type="monotone"
          dataKey="business"
          stroke="#BEC001"
          strokeWidth={2.5}
          fill="url(#colorBusiness)"
        />
        <Area
          type="monotone"
          dataKey="chatbot"
          stroke="#ffc658"
          strokeWidth={2.5}
          fill="url(#colorChatbot)"
        />
      </AreaChart>
    </ResponsiveContainer>
  </Box>
);

const App = () => {
  
  const { isanalytics, isloading, updatetime } = UseAnalytics();

  
  // console.log("today looks likes this :",today);

  // const handleFormatDate = useCallback((date) => {
  //   const getdate = new Date(date);

  //   console.log("get data looks :",getdate);
  //   return  getdate.toISOString().split("T")[0]; // Extracts the date part
  // }, []);



  const formatDates = () => {
    const today = new Date();
    const sevenDaysAgo = new Date();
  
    // Adjust the sevenDaysAgo date by subtracting 7 days
    sevenDaysAgo.setDate(today.getDate() - 7);
  
    // Format both dates to "YYYY-MM-DD"
    const formattedToday = today.toISOString().split("T")[0];
    const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];
  
    return { formattedToday, formattedSevenDaysAgo };
  };
  
  const today = new Date();
  const { formattedToday, formattedSevenDaysAgo } = formatDates();
  // const initialvalue = handleFormatDate(today);
  
  console.log( "formated date looks likes this ",formattedToday, formattedSevenDaysAgo); // Output: "2024-12-11"
  const [data, setData] = useState(isanalytics); // Chart data
  const [range, setRange] = useState("7 days"); // Default range
  const [fromDate, setFromDate] = useState(formattedSevenDaysAgo); // Default from date
  const [toDate, setToDate] = useState(formattedToday); // Default to date
  // console.log("from data :", fromDate);
  // console.log("to data :", toDate);

  console.log("analytics data looks likes this :", isanalytics);

  const [istotalmessage,settotalmessage] =useState([]);

  useEffect(() => {
    if (!isanalytics || !Array.isArray(isanalytics)) {
      console.error("isanalytics is not valid:", isanalytics);
      return;
    }
  
    const total = isanalytics.reduce(
      (acc, curr) => {
        acc.user += curr.user;
        acc.business += curr.business;
        acc.chatbot += curr.chatbot;
        return acc;
      },
      { user: 0, business: 0, chatbot: 0 }
    );
  
    settotalmessage(total)
  }, [isanalytics]);
  

  // const total = isanalytics?.reduce(
  //   (acc, curr) => {
  //     acc.user += curr.user;
  //     acc.business += curr.business;
  //     acc.chatbot += curr.chatbot;
  //     return acc;
  //   },
  //   { user: 0, business: 0, chatbot: 0 }
  // );

  // console.log(total);
 
  // Helper to filter data based on range
  const filterData = (newFromDate, newToDate) => {
    // Assuming real dates are available in your data
    const filteredData = isanalytics?.filter((entry) => {
      const entryDate = new Date(
        `2024-${entry.date.split(" ")[1]}-${entry.date.split(" ")[0]}`
      );
      return (
        entryDate >= new Date(newFromDate) && entryDate <= new Date(newToDate)
      );
    });
    setData(filteredData);
  };

  // Handle dropdown selection

  // Handle manual date changes
  const handleFromDateChange = (e) => {
    const newValue = e.target.value;
    setFromDate(newValue);
    filterData(newValue, toDate);
    setRange("Custom Range");

    // console.log("From Date:", newValue);
  };

  
  const handleRangeChange = async (selectedRange) => {
    const today = new Date();
    let newFromDate = today.toISOString().split("T")[0]; // Today's date in YYYY-MM-DD

    switch (selectedRange) {
      case "Today":
        newFromDate = today.toISOString().split("T")[0];
        break;
      case "Yesterday":
        newFromDate = new Date(today.setDate(today.getDate() - 1))
          .toISOString()
          .split("T")[0];
        break;
      case "7 days":
      case "7 Days":
        newFromDate = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        break;
      case "30 days":
      case "30 Days":
        newFromDate = new Date(today.setDate(today.getDate() - 30))
          .toISOString()
          .split("T")[0];
        break;
      default:
        newFromDate = today.toISOString().split("T")[0];
    }

    setFromDate(newFromDate);
    setToDate(new Date().toISOString().split("T")[0]);
    filterData(newFromDate, new Date().toISOString().split("T")[0]);

    console.log("from data looks likes this :", fromDate);
    console.log("to data looks likes this :.", toDate);

    // await handlefetchData(fromDate,toDate);
    // console.log("updatdated data:",updateData)
    // await  updatetime(updateData);
  };

  const handleToDateChange = async (e) => {
    const newValue = e.target.value;
    setToDate(newValue);
    filterData(fromDate, newValue);
    setRange("Custom Range");
    // const updateData = {
    //   startDate: fromDate,
    //   endDate: toDate,
    // };

    // console.log("update data in range looks likes this :", updateData);
    //  await updatetime(updateData);
    // await handlefetchData(updateData);
    // await handlefetchData(fromDate,toDate);
    // console.log("To Date:", newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (fromDate && toDate) {
        await handlefetchData(fromDate, toDate);
      }
    };
    fetchData();
  }, [fromDate, toDate]);
  const handlefetchData = async (fromDate, toDate) => {
    const updateData = {
      startDate: fromDate,
      endDate: toDate,
    };
    await updatetime(updateData);
  };

  return (
    <Box sx={{ padding: 4 }}>
      {/* Filters */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        {/* Dropdown for Quick Ranges */}
        <Select
          value={range}
          onChange={(e) => {
            setRange(e.target.value);
            handleRangeChange(e.target.value);
          }}
          size="small"
          sx={{ width: 150 }}
        >
          <MenuItem value="Today">Today</MenuItem>
          <MenuItem value="Yesterday">Yesterday</MenuItem>
          <MenuItem value="7 days">7 Days</MenuItem>
          <MenuItem value="30 days">30 Days</MenuItem>
          {range === "Custom Renge" && (
            <MenuItem value="Custom Renge">Custom Range</MenuItem>
          )}
        </Select>

        <TextField
          label="From"
          type="date"
          size="small"
          value={fromDate}
          onClick={(e) => e.target.showPicker()} // Show the date picker on input click
          onChange={handleFromDateChange}
          inputProps={{
            max: toDate, // Prevent selecting future dates
          }}
          sx={{ width: 150 }}
        />

        <TextField
          label="To"
          type="date"
          size="small"
          value={toDate}
          onClick={(e) => e.target.showPicker()} // Show the date picker on input click
          onChange={handleToDateChange}
          inputProps={{
            max: new Date().toISOString().split("T")[0], // Prevent selecting future dates
            min: fromDate, // Prevent selecting a date before "From"
          }}
          sx={{ width: 150 }}
        />
      </Box>

      {/* Analytics Chart */}
      <AnalyticsChart data={isanalytics} 
      total={istotalmessage}
      />
    </Box>
  );
};

export default App;

// import React from "react";
// import { Box, Typography, TextField } from "@mui/material";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
//   defs,
// } from "recharts";

// const data = [
//   { date: "30 Nov", user: 54, business: 474, chatbot: 18 },
//   { date: "01 Dec", user: 150, business: 10, chatbot: 50 },
//   { date: "02 Dec", user: 300, business: 600, chatbot: 100 },
//   { date: "03 Dec", user: 50, business: 0, chatbot: 30 },
// ];

// const AnalyticsChart = () => (
//   <Box>
//     <Typography variant="h6" sx={{ marginBottom: 2 }}>
//       Chats (per day)
//     </Typography>
//     <ResponsiveContainer width="100%" height={300}>
//       <AreaChart data={data}>
//         {/* Gradient for Smooth Background */}
//         <defs>
//           <linearGradient id="colorUser" x1="0" y1="0" x2="0" y2="1">
//             <stop offset="0%" stopColor="#8884d8" stopOpacity={0.8} />
//             <stop offset="100%" stopColor="#8884d8" stopOpacity={0} />
//           </linearGradient>
//           <linearGradient id="colorBusiness" x1="0" y1="0" x2="0" y2="1">
//             <stop offset="0%" stopColor="#BEC001" stopOpacity={0.8} />
//             <stop offset="100%" stopColor="#BEC001" stopOpacity={0} />
//           </linearGradient>
//           <linearGradient id="colorChatbot" x1="0" y1="0" x2="0" y2="1">
//             <stop offset="0%" stopColor="#ffc158" stopOpacity={0.8} />
//             <stop offset="100%" stopColor="#ffc658" stopOpacity={0} />
//           </linearGradient>
//         </defs>

//         {/* Grid and Axis */}
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="date" />
//         <YAxis domain={[0, "dataMax"]} />
//         <Tooltip />

//         {/* Area Chart Lines */}
//         <Area
//           type="monotone"
//           dataKey="user"
//           stroke="#8884d8"
//           strokeWidth={2.5}
//           fill="url(#colorUser)"
//         />
//         <Area
//           type="monotone"
//           dataKey="business"
//           stroke="#BEC001"
//           strokeWidth={2.5}
//           fill="url(#colorBusiness)"
//         />
//         <Area
//           type="monotone"
//           dataKey="chatbot"
//           stroke="#ffc658"
//           strokeWidth={2.5}
//           fill="url(#colorChatbot)"
//         />
//       </AreaChart>
//     </ResponsiveContainer>
//   </Box>
// );

// const App = () => {
//   return (
//     <Box sx={{ padding: 4 }}>
//       {/* Filters */}
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-between",
//           marginBottom: 3,
//         }}
//       >
//         <TextField
//           label="From"
//           variant="outlined"
//           size="small"
//           type="date"
//           defaultValue="2024-11-30"
//           sx={{ width: 150 }}
//         />
//         <TextField
//           label="To"
//           variant="outlined"
//           size="small"
//           type="date"
//           defaultValue="2024-12-07"
//           sx={{ width: 150 }}
//         />
//       </Box>

//       {/* Analytics Chart */}
//       <AnalyticsChart />
//     </Box>
//   );
// };

// export default App;
