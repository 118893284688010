import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";

const useGenerateOtp = () => {
  const [loading, setLoading] = useState(false);
  const [otpSendSuccess, setOtpSendSuccess] = useState(false);

  const generateOtp = async (username) => {
    console.log(username);
    if (!username) {
      return toast.error("fill the username first!");
    }
    try {
      setLoading(true);

      const res = await axios.post("/api/auth/generateOTP", { username });

      const data = res.data;

      toast.success(data.message);

      if (data.message) { 
        setOtpSendSuccess(true);
      }
    } catch (error) {
      console.error("erron in generateOtp",error.response?.data?.error || error.message);
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, otpSendSuccess, generateOtp };
};

export default useGenerateOtp;
