import { useEffect, useCallback } from "react";
import { useSocketContext } from "../context/SocketContext";
import { UseAuthContext } from "../context/AuthContext";
import useLogOut from "./useLogOut";
import toast from "react-hot-toast";

const useSessionActive = () => {
  const { socket } = useSocketContext(); // Use socket from context
  const { authUser } = UseAuthContext();
  const { logOut } = useLogOut();

  const handleSessionActive = useCallback(
    (sessionActive) => {
      console.log("useSessionActive:", sessionActive);

      if (authUser?.agentId === sessionActive?.deletedAgentId) {
        logOut();
        toast.success("Session terminated.");
      } else if (sessionActive?.decoded === false) {
        logOut();
        toast.success("Session terminated.");
      } else {
        return;
      }
    },
    [socket, authUser]
  );

  useEffect(() => {
    if (!socket) return;

    socket.on("sessionActive", handleSessionActive);

    return () => {
      socket.off("sessionActive", handleSessionActive);
    };
  }, [socket, handleSessionActive]);

  return null; // This is a hook, no UI to render
};

export default useSessionActive;
