import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

function InputCard({ data }) {
  return (
    <>
      <Card
        sx={{
          width: "100%",
          border: "none",
          boxShadow: "none",
          margin: "0px",
          padding: "0px",
          minHeight: "118px",
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h3" component="div">
            {data.heading}
          </Typography>
          {data.Other && (
            <div>
              <Typography variant="body1" color="text.secondary">
                {data.Other.tag1}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {data.Other.tag2}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {data.Other.tag3}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {data.Other.tag4}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default InputCard;
