import axios from "axios";
import { useEffect, useState } from "react";
import useZusConversation from "../zustand/useZusConversation";
import toast from "react-hot-toast";

const useGetConversations = () => {
  const { loadingCNV, setLoadingCNV, conversations, setConversations } =
    useZusConversation();

  useEffect(() => {
    setLoadingCNV(true);

    const getConversations = async () => {
      try {
        const res = axios.get("/api/client/users");

        const data = (await res).data;

        // console.log(data.data);

        setConversations(data);
      } catch (error) {
        toast.error(error.response?.data?.error || error.message);
      } finally {
        setLoadingCNV(false);
      }
    };

    getConversations();
  }, []);

  return { loadingCNV, conversations, setConversations };
};

export default useGetConversations;
