import React, { useState, useEffect } from "react";
import "./ClientProfile.css";
import { MdOutlineShowChart } from "react-icons/md";
import { RiCheckDoubleLine } from "react-icons/ri";
import useZusConversation from "../../../zustand/useZusConversation";
import { formatDate } from "../../../utils/extractTime";
import { useChangeOptin } from "../../../hooks/useOptin";
import toast from "react-hot-toast";

const ClientProfile = () => {
  const { selectedConversation } = useZusConversation();
  const { loading, changeOptStatus } = useChangeOptin();

  // Initialize the state with a default value
  const [optin, setOptin] = useState(false);

  useEffect(() => {
    if (selectedConversation?.OptedIn instanceof Promise) {
      selectedConversation.OptedIn.then((result) => {
        setOptin(result);
      }).catch((error) => {
        console.error("Error fetching OptedIn:", error);
      });
    } else {
      setOptin(selectedConversation?.OptedIn || false);
    }
  }, [selectedConversation]);

  console.log("joinedOn:", selectedConversation);

  const handleChangeOptin = async (e) => {
    const isChecked = e.target.checked;
    console.log("from front:", isChecked);

    const userConfirmed = window.confirm(
      `Are you sure you want to change the opt-in status to ${
        isChecked ? "enabled" : "disabled"
      }?`
    );

    console.log("userConfirmed:", userConfirmed);
    console.log("from front after confirm:", isChecked);

    if (userConfirmed) {
      await changeOptStatus(selectedConversation?._id, isChecked);
      setOptin(isChecked);
    } else {
      // Revert the checkbox state
      e.target.checked = optin;
    }
  };

  const lastActiveDate = formatDate(selectedConversation?.last_Active);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isCollapse1, setIsCollapse1] = useState(false);
  const [isCollapse2, setIsCollapse2] = useState(false);
  const [isCollapse3, setIsCollapse3] = useState(false);
  const [isCollapse4, setIsCollapse4] = useState(false);

  return (
    <div
      className={`border-none w-1/4 bg-[#F8F8F8] flex flex-col text-black ${
        !selectedConversation && "blur-sm pointer-events-none"
      }`}
    >
      <>
        {/* HEADER */}
        <div className="bg-[#0A474C] text-white text-base font-medium text-center px-4 py-4 mb-2">
          Chat Profile
        </div>
        <div className="overflow-auto">
          <div className="flex justify-center my-5 items-center">
            <div className="w-14 h-14 me-2 bg-[#FFA500] flex items-center justify-center text-3xl font-normal text-white rounded-full">
              {selectedConversation?.name.charAt(0)}
            </div>
            <div className="font-medium ms-2">
              <div className="text-lg">{selectedConversation?.name}</div>
              <div>+{selectedConversation?.mobile_Number}</div>
            </div>
          </div>

          <div className="divider divider-neutral opacity-20 w-[16vw] mx-auto mt-1" />
          <div className="bg-[#EBF5F3] text-xs rounded-lg mx-4 p-4 font-medium">
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Status</h6>
              <p className="w-1/2">Active</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Last Active</h6>
              <p className="w-1/2 text-xs font-normal">{lastActiveDate}</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Template Messages</h6>
              <p className="w-1/2">
                {selectedConversation?.templateSendCount || 0}
              </p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Source</h6>
              <p className="w-1/2">{selectedConversation?.source}</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">First Message</h6>
              <p className="w-1/2">...</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Incoming</h6>
              <p className="w-1/2">Allowed</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Chat expiresAt</h6>
              <p className="w-1/2 text-xs font-normal">
                {formatDate(selectedConversation?.expiresAt)}
              </p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Joined On</h6>
              <p className="w-1/2 text-xs font-normal">
                {formatDate(selectedConversation?.createdAt)}
              </p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Opted In</h6>
              <input
                type="checkbox"
                disabled={loading}
                checked={optin}
                onChange={(e) => handleChangeOptin(e)}
                className="toggle toggle-sm toggle-success"
              />
            </div>
          </div>

          <div className="px-4">
            <div
              className="collapse collapse-arrow rounded-md bg-white mt-4"
              onClick={() => {
                setIsCollapse2(!isCollapse2);
              }}
            >
              <input type="radio" name="my-accordion-3" checked={isCollapse2} />
              <div className="collapse-title textarea-md font-medium">
                Attributes
              </div>
              <div className="collapse-content text-gray-600 font-medium">
                <p>Coming soon...</p>
              </div>
            </div>
            <div
              className="collapse collapse-arrow rounded-md bg-white mt-4"
              onClick={() => {
                setIsCollapse3(!isCollapse3);
              }}
            >
              <input type="radio" name="my-accordion-4" checked={isCollapse3} />
              <div className="collapse-title textarea-md font-medium">Tags</div>
              <div className="collapse-content text-gray-600 font-medium">
                <p>{selectedConversation?.tags}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ClientProfile;
