import { Button } from "@mui/material";
import React, { useState } from "react";
import { UseIntervence } from "../../../hooks/useAgenst";

const Intervened = ({ currentauthuser, selectedContact }) => {
  const [input, setInput] = useState("");
  const { isintervence, isloading, updateintervence } = UseIntervence();

  console.log("current authusers:", currentauthuser);
  console.log("selected contact", selectedContact);

  const hadleOnSubmit = async (e) => {
    updateintervence({
      contactId: selectedContact,
      agentId: currentauthuser,
    });
    e.preventDefault();
  };

  return (
    <form className="px-4 z-50">
      <div className="w-full bg-white rounded-md mb-2 relative py-4 px-5 text-center">
        {/* <div className="font-bold text-base">This chat is expired</div> */}
        {/* <div className="font- mt-0.5 text-gray-900">
          Chats are marked as expired 24 hours after the last received customer
          message. WhatsApp allows only template messages to be sent in such
          chats.
        </div> */}

        <Button
          variant="outlined"
          // sx={{
          //   background: "#23A455",
          //   ":hover": { background: "#23A455" },
          // }}
          // endIcon={<SendIcon />}
          // className=""
          onClick={(e) => {
            hadleOnSubmit(e);
          }}
          // disabled={loading}
          type="submit"
        >
          Intervene
        </Button>
      </div>
    </form>
  );
};

export default Intervened;
