import React, { useState } from "react";
import { MdImage } from "react-icons/md";
import { IoPlayCircle } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { BiVideo } from "react-icons/bi";
import { BsPaperclip } from "react-icons/bs";
import { IoEllipsisVertical } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineCall } from "react-icons/md";
import { BiLinkExternal } from "react-icons/bi";
import { CgMailReply } from "react-icons/cg";
import { CgSmileMouthOpen } from "react-icons/cg";
import { MdOutlineCameraAlt } from "react-icons/md";
import { IoMdMic } from "react-icons/io";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

function CarousalCard({
  selectedType,
  inputValue,
  childState,
  inputBubble,
  cardformat,
  cards,
  setCards,
  addcards,
  removecards,
  handleNextCard,
  activeCardIndex,
  handlePreviousCard,
  footer,
}) {
  // const [cards, setCards] = useState([]);

  // const addcards = () => {
  //   setCards([...cards, { id: cards.length + 1, cardData: "" }]);
  // };
  const maxcount = 10;

  console.log("input bubbles looks likes :", inputBubble);
  console.log("card format in  carousal data looks likes :", cardformat);
  const formatText = (text) => {
    return text
      .replace(/\*(.*?)\*/g, "<b>$1</b>") // Bold
      .replace(/_(.*?)_/g, "<i>$1</i>") // Italic
      .replace(/~(.*?)~/g, "<s>$1</s>"); // Strikethrough
  };

  console.log("cards data looks likes this:", cards);

  return (
    <>
      <div className="container  flex justify-center">
        <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
          <div className="camera "></div>
          <div className="display bg-white min-h-[450px] relative ">
            <div className="flex flex-col min-h-[500px]  bg-gray-100">
              <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                  <span className="text-black font-bold">T</span>
                </div>
                <div className="flex-grow">
                  <div className="font-semibold mt-3">TheBotmode</div>
                  <div className="text-xs  font-normal ">online</div>
                </div>
                <div className="flex items-center h-[45px] mt-3 ">
                  <BiVideo className="mx-2 text-lg text-white" />
                  <MdOutlineCall className="mx-1 text-lg text-white" />
                  <IoEllipsisVertical className="mx-1 text-lg text-white" />
                </div>
              </div>

              {/* Chat area */}
              <div className="flex-grow bg-gray-200 p-4 relative">
                <img
                  src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
                  className="h-full w-full object-cover absolute inset-0"
                  alt="whatsapp background"
                />

                <span
                  className={`absolute top-52 left-2 h-6 w-6 bg-[#E7E7E7] rounded-full flex justify-center items-center z-[3] cursor-pointer ${
                    activeCardIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handlePreviousCard}
                >
                  <MdOutlineKeyboardArrowLeft className="text-lg text-gray-700 " />
                </span>
             {cards[activeCardIndex]?.cardData.length > 3  && ( 
              <div>
                 <span
                  className={`absolute top-52 right-6 h-6 w-6 bg-[#E7E7E7] rounded-full flex justify-center items-center z-[3] cursor-pointer ${
                    activeCardIndex === cards.length - 1
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={handleNextCard}
                >
                  <MdOutlineKeyboardArrowRight className="text-lg text-gray-700 " />
                </span>
              </div>
             )}

               
                <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55">
                  <span className="text-xs text-center  font-normal  text-[#54656F]">
                    Today
                  </span>
                </div>
                <div className="absolute rounded-t-md top-15 left-2 w-10/14  max-h-[calc(100%-96px)] overflow-hidden overflow-y-auto custom-scroll">
                  {/* <div className="shadow p-2 text-center    overflow-hidden "> */}
                  <div className="min-h-16 p-2 bg-white shadow  max-w-56  text-start break-words overflow-hidden  whitespace-pre-wrap">
                    {/* <RiWhatsappFill className="text-green-500 text-4xl absolute top-[-16px] left-[-14px]" /> */}
                    {/* <div className="absolute top-[5px] left-4 text-sm  font-medium  items-start text-start mt-2 "> */}
                    <div
                      className=" overflow-hidden "
                      dangerouslySetInnerHTML={{
                        __html: formatText(inputBubble || ""),
                      }}
                    />
                    {/* </div> */}
                  </div>

                  {/* <div className="w-10/14 mx-auto mt-[2px]  max-h-[calc(100%-96px)] overflow-y-auto custom-scroll"> */}
                  <div className="bg-white shadow w-full flex rounded-md p-1  mt-1 text-center relative   ">
                    {cards.map(
                      (card, index) =>
                        index === activeCardIndex && (
                          <div key={card.id}>
                            {selectedType !== "None" &&
                              selectedType !== "Text" && (
                                <div className="w-[215px] h-40 bg-[#F8F8F8] rounded-md flex gap-5 justify-center items-center ">
                                  {/* {cards.length <= 1  || activeCardIndex === 0 ? (
                                    <button disabled >
                                      
                                       <span
                                    className="absolute top-[-4px] right-0 h-6 w-6 bg-[#E7E7E7]  rounded-full flex justify-center items-center"
                                    onClick={() => removecards(card.id)}
                                  >
                                    <MdDelete className="text-sm text-[#808080]" />
                                  </span>
                                    </button>
                                  ):(
                                    <button>
                                       <span
                                    className="absolute top-[-4px] right-0 h-6 w-6 bg-[#E7E7E7]  rounded-full flex justify-center items-center"
                                    onClick={() => removecards(card.id)}
                                  >
                                    <MdDelete className="text-sm text-[#808080]" />
                                  </span>
                                    </button>
                                  )} */}

                                  {cards.length > 1 && activeCardIndex > 0  && (
                                    <button>
                                      
                                         <span
                                         className="absolute top-[-4px] right-0 h-6 w-6 bg-[#E7E7E7]  rounded-full flex justify-center items-center"
                                         onClick={() => removecards(card.id)}
                                       >
                                         <MdDelete className="text-sm text-[#808080]" />
                                       </span>
                                    
                                     
                                    </button>
                                  )}

                                  {cardformat === "IMAGE" && (
                                    <div className="text-4xl text-yellow-500">
                                      <MdImage />
                                    </div>
                                  )}
                                  {cardformat === "VIDEO" && (
                                    <div className="text-5xl text-blue-600">
                                      <IoPlayCircle />
                                    </div>
                                  )}
                                </div>
                              )}

                            <div className="text-sm font-medium mt-3  break-words  max-w-52  whitespace-pre-wrap   text-start overflow-hidden ">
                              <div
                                className=" overflow-hidden "
                                dangerouslySetInnerHTML={{
                                  __html: formatText(card.cardData || ""),
                                }}
                              />
                              <div className="text-xs font-normal text-start text-blue-600 mt-4 ">
                                {footer}
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>

                  {childState.isPhoneNumber.map((phoneNumberArray, index) => (
                    <div
                      key={index}
                      className="templatebuttons flex gap-1 items-center justify-center border-blue border-1"
                    >
                      <MdOutlineCall className="text-blue-600 text-sm" />
                      {phoneNumberArray[0]}
                    </div>
                  ))}

                  {childState.isUrl.map((urlArray, index) => (
                    <div
                      key={index}
                      className="templatebuttons flex gap-1 items-center justify-center border-blue border-1"
                    >
                      <BiLinkExternal className="text-blue-600 text-sm" />
                      {urlArray[1]}
                    </div>
                  ))}

                  {childState.input.map((button, index) => (
                    <div
                      key={index}
                      className="templatebuttons rounded-b-md flex gap-1 items-center justify-center border-blue border-1"
                    >
                      <CgMailReply className="text-blue-600 text-sm" />
                      {button}
                    </div>
                  ))}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>

              {/* Input area */}
              <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">
                <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                  <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                  <input
                    type="text"
                    placeholder="Type a message"
                    className="w-[60%] outline-none"
                  />

                  <BsPaperclip className="text-gray-600  mx-2 text-lg" />
                  <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                </div>
                <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                  <IoMdMic className="text-white text-lg " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        {cards.length === maxcount ? (
          <div className="text-gray-500 mt-6 text-center font-normal ">
            <span>you can add only 10 cards</span>
          </div>
        ) : (
          <div>
            <div
              className="mt-4 font-normal text-xs text-stone-400 btn btn-md "
              onClick={addcards}
            >
              ADD more cards
            </div>
          </div>
        )}
      </div> */}
    </>
  );
}

export default CarousalCard;
