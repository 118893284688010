import React, { useState } from "react";

import MessageSetupCreateTemplate from "../GlobalCmp/MessageSetupCreateTemplate";
import { Toaster } from "react-hot-toast";

function SetUpMesssageCard({
  title,
  cardBody,
  Cardtype,
  imageUrl,
  templatename,
}) {
  // const [istemplatename,settemplatecard] = useState(templatename);

  const handlevclosemodle = () => {
    document.getElementById("setupmessagemodle").close();
  };
  return (
    <>
      <div className=" w-[300px]  shadow rounded-sm bg-white h-[400px] flex-col flex-wrap justify-center items-center my-5">
        <div className="w-[200px] mx-auto  h-[200px]  flex justify-center items-center  ">
          <img
            // src="https://cdn-icons-png.flaticon.com/512/5867/5867363.png"
            src={imageUrl}
            className="h-[100px] w-[100px]  mt-3 "
          ></img>
        </div>
        <div className="w-[250px] mx-auto text-center">{title}</div>
        <div className="w-full  flex items-center justify-center">
          <div className="btn btn-sm  bg-blue-300 bg-opacity-15 rounded-md">
            {" "}
            {/* text */}
            {Cardtype}
          </div>
        </div>

        <div className="min-h-12  max-h-14 w-[250px]  bg-white text-gray-500 mx-auto font-normal overflow-hidden">
          {/* <p>Thanks for your response. We’ll get back to you soon.</p> */}
          <p>{cardBody}</p>
        </div>

        <div className="w-full  flex items-center justify-center">
          <button
            className="btn btn-sm bg-green-900  text-white mt-2 hover:bg-green-900  hover:text-white"
            onClick={() =>
              document.getElementById("setupmessagemodle").showModal()
            }
          >
            Review and submit
          </button>
        </div>
        <dialog id="setupmessagemodle" className="modal">
          <div className="modal-box min-w-[1000px] h-screen bg-gray-100">
            <form method="dialog">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                ✕
              </button>
            </form>
            <h2 className="text-xl font-normal">{title}</h2>

            <MessageSetupCreateTemplate templatename={templatename} />
          </div>


          <Toaster
        toastOptions={{
          style: {
            zIndex: 9999, // Ensure toast stays above the dialog
          },
        }}/>

        </dialog>
      </div>
    </>
  );
}

export default SetUpMesssageCard;
