
import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoDocumentText, IoImageSharp, IoPlayCircle } from "react-icons/io5";

function DocumentTemplate({ document }) {
  console.log("Document template:", document);

  const mediaType = document?.mediaType?.toUpperCase();
  let content = null;

  // Determine content based on media type
  switch (mediaType) {
    case "IMAGE":
      content = document?.mediaUrl ? (
        <img src={document?.mediaUrl} className="w-auto h-auto rounded-md" alt="template image" />
      ) : (
        <IoImageSharp className="h-40 w-80 bg-orange-300 rounded-md" />
      );
      break;

    case "VIDEO":
      content = document?.mediaUrl ? (
        <video width="300" controls muted>
          <source src={document?.mediaUrl} />
        </video>
      ) : (
        <IoPlayCircle className="h-40 w-72 text-blue-600" />
      );
      break;

    case "DOCUMENT":
      content = document?.mediaUrl ? (
        <iframe
          src={document?.mediaUrl}
          className="w-full h-40 border rounded-md"
          title="Document Preview"
          frameBorder="0"
        ></iframe>
      ) : (
        <IoDocumentText className="h-40 w-72 text-red-400" />
      );
      break;

    case "LOCATION":
      content = <FaLocationDot className="h-40 w-72 text-red-600" />;
      break;

    case "TEXT":
      content = (
        <div className="w-full bg-white p-2">
          <p className="font-bold text-gray-700">{document?.caption}</p>
        </div>
      );
      break;

    default:
      content = <p className="text-gray-500">Unsupported media type</p>;
      break;
  }

  return (
    <div className="p-2 rounded-md bg-white shadow-md w-[65%]">
      <div className="rounded-md overflow-hidden">
        <figure className="mb-2">{content}</figure>
        {document?.documentData?.caption && (
          <div className="p-2 text-sm bg-white font-normal overflow-hidden whitespace-pre-wrap">
            <p className="text-gray-800">{document?.documentData?.caption}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default DocumentTemplate;