import React, { useEffect, useState } from "react";
import GlobalHeader from "../../components/GlobalComponets/GlobalHeader";
import PhoneInput from "react-phone-number-input";
import { Autocomplete, TextField } from "@mui/material";
import { TextComponent } from "../../components/template/CreateTemplate";
import {
  Usecreateagents,
  usecreateagents,
  Usegetagents,
} from "../../hooks/useAgenst";
import toast, { Toaster } from "react-hot-toast";
import AgentsCard from "../../components/Agents/AgentsCard";
import { UseAuthContext } from "../../context/AuthContext";

function Agent() {
  
  const { iscreateAgent, isloader, uploadDataAgents } = Usecreateagents();

  //get all the agents
  const {
    agents: isAllagents,
    isagentsloading,
    getagents,
    refetchusername,
  } = Usegetagents();
  console.log("all agents looks likes this :", isAllagents);
  // useEffect(() => {
  //   console.log("All agents data:", isAllagents);
  //   // getagents()                                  // This should log the agents once the data is fetched
  // }, [isAllagents]); // Log the data whenever it changes
  const DetailsOfGlobalHeader = {
    Title: "Agents",
    Details: false,
  };

  const [ismanagment, setmanagment] = useState("");
  const [isAgentsPhoneNumber, setAgentsPhonenumber] = useState("");
  const [isAgentsname, setAgentsname] = useState("");
  const [isAgentsmail, setAgentsmail] = useState("");
  const [isAgentsusername, setAgentsusername] = useState("");
  const [isAgentspassword, setAgentspasswords] = useState("");
  console.log("is agents number looks likes this  :", isAgentsPhoneNumber);
  console.log("is agents name looks likes this  :", isAgentsname);
  console.log("is agents mail looks likes this  :", isAgentsmail);
  console.log("is agents username looks likes this  :", isAgentsusername);
  console.log("is agents password looks likes this  :", isAgentspassword);
  console.log("selecetd option looks likes this :", ismanagment);
  const Handlemanagement = (event, newValue) => {
    if (newValue) {
      setmanagment(newValue.label); // Use `id` instead of `code`
    } else {
      setmanagment(""); // Reset state when selection is cleared
    }
  };

  const managementoption = [
    { label: "Agent", id: 1 },
    { label: "Manager", id: 2 },
  ];

  const handlecreateagents = async () => {
    console.log("this handlecreate function is triggred");
    try {
      if (!isAgentsmail.includes("@") || !isAgentsmail.includes(".")) {
        toast.error("Enter a valid email");
        return;
      }
      const updatedata = {
        name: isAgentsname,
        email: isAgentsmail,
        mobile_Number: isAgentsPhoneNumber,
        user_Role: ismanagment,
        user_Name: isAgentsusername.toLowerCase(),
        user_Password: isAgentspassword,
      };
      const requiredFields = [
        "name",
        "email",
        "mobile_Number",
        "user_Role",
        "user_Name",
        "user_Password",
      ];
      for (const field of requiredFields) {
        if (!updatedata[field]) {
          toast.error(`${field} is required`);
          return;
        }
      }
      console.log("function reached here ", updatedata);
      await uploadDataAgents(updatedata);

      refetchusername();
      document.getElementById("agent_box").close();
    } catch (error) {
      console.error("something went wrong while creating agents ");
    }
  };

  const handlecloseagentcreateion = () => {};

  const cardDetails = [
    {
      Username: "10xexportstudents@gmail.com",
      currentpostion: "Owner",
      currentstatus: "online",
      id: "1",
    },
    {
      Username: "Kanishka",
      currentpostion: "Agent",
      currentstatus: "Offline",
      id: "2",
    },
    {
      Username: "koregrowsagar",
      currentpostion: "Agent",
      currentstatus: "Offline",
      id: "3",
    },
  ];

  return (
    <div>
      <GlobalHeader item={DetailsOfGlobalHeader} />
      <div className="  mx-9 h-24 flex items-center justify-between">
        <span>Your team for Assistant management</span>
        <button
          className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
          onClick={() => document.getElementById("agent_box").showModal()}
        >
          {" "}
          + Add agent{" "}
        </button>
        <dialog id="agent_box" className="modal ">
          <div className="modal-box bg-white">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                ✕
              </button>
            </form>
            <div className="text-xl  ">Create Agent</div>
            <input
              type="text"
              placeholder="Agent's Name "
              className="input bg-[#F0F0F0]  w-full text-sm mt-4"
              // value={UrlMessage.urlInput}
              required
              // onChange={(e) => handleURlChange(e, index)}
              onChange={(e) => setAgentsname(e.currentTarget.value)}
            />
            <input
              type="text"
              placeholder="Agent's Email"
              className="input bg-[#F0F0F0]  w-full text-sm mt-4"
              // value={UrlMessage.urlInput}
              required
              // onChange={(e) => handleURlChange(e, index)}
              onChange={(e) => setAgentsmail(e.currentTarget.value)}
            />

            <div className="w-full  phone-input-container1   text-xs">
              <PhoneInput
                getOptionLabel={(option) => option.label || "default"}
                international
                defaultCountry="IN"
                // name="Numberinput2"
                // id="inputnumber2"
                // value={SingleNumberInput.Numberinput2}
                required
                // onChange={(value) =>
                //   handlePhoneNumberChange(null, index, value)
                // }
                value={isAgentsPhoneNumber}
                onChange={(value) => setAgentsPhonenumber(value)}
                // onChange={(e) => setAgentsPhonenumber(e.target.value)}
              />
            </div>

            <div className="w-full ">
              <Autocomplete
                disablePortal
                id="TemplateLanguage"
                options={managementoption}
                sx={{
                  width: "100%",
                  marginTop: "25px",
                  background: "#F0F0F0",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                    {
                      padding: "7.5px 4px 7.5px 13px",
                    },
                  "& .css-1b9mdof-MuiFormLabel-root-MuiInputLabel-root": {
                    color: "#A9A9A9",
                    fontSize: "medium",
                  },
                }}
                onChange={Handlemanagement}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select what you want to create"
                  />
                )}
              />
            </div>

            <div className="w-full mt-3">
              <span>Login details</span>
              <input
                type="text"
                placeholder="Agent's username"
                className="input bg-[#F0F0F0]  w-full text-sm mt-4"
                // value={UrlMessage.urlInput}
                required
                // onChange={(e) => setAgentsPhonenumber(e.target.value)}
                onChange={(e) => setAgentsusername(e.currentTarget.value)}
              />
              <input
                type="text"
                placeholder="Agent's password "
                className="input bg-[#F0F0F0]  w-full text-sm mt-4"
                // value={UrlMessage.urlInput}
                required
                // onChange={(e) => handleURlChange(e, index)}
                onChange={(e) => setAgentspasswords(e.currentTarget.value)}
              />
            </div>
            {/* <div className="modal-action"> */}
            {/* <form method="dialog"> */}
            {/* if there is a button in form, it will close the modal */}
            <div className="flex gap-4 items-center justify-end mt-3">
              <button
                className="btn btn-sm bg-transparent text-[#0A474C] border-[#0A474C] hover:bg-[#EDF0F0] rounded-md -mr-2"
                onClick={() => document.getElementById("agent_box").close()}
              >
                Close
              </button>
              <button
                className={`btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]  ${
                  isloader ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handlecreateagents}
                disabled={isloader} // Disable the button when loading
              >
                {isloader ? "Loading..." : "Create"}
              </button>
              {/* btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e] */}
            </div>
            {/* </form> */}
            {/* </div> */}
            {/* <button
              className={`btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]  ${
                isloader ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handlecreateagents}
              disabled={isloader} // Disable the button when loading
            >
              {isloader ? "Loading..." : "Create"}
            </button> */}

            <Toaster
              toastOptions={{
                style: {
                  zIndex: 9999, // Ensure toast stays above the dialog
                },
              }}
            />
          </div>
        </dialog>
      </div>
      <div className="flex flex-wrap  gap-4">
        {isAllagents === undefined || !isAllagents ? (
          <div className="items-center justify-center">create agents</div>
        ) : (
          <>
            {isAllagents?.map((data, index) => (
              <div key={data?._id} className="min-w-[40%]">
                {" "}
                <AgentsCard
                  name={data?.name}
                  username={data?.user_Name}
                  status={data?.currentstatus}
                  currentposition={data?.user_Role}
                  email={data?.email}
                  phonenumber={data?.mobile_Number}
                  user_Role={data?.user_Role}
                  id= {data?._id}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Agent;
