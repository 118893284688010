import React from "react";

export default function TableHeader() {
  return (
    <>
      <thead className=" bg-gray-50 border rounded-md text-sm font-normal">
        <tr className="w-14">
          <th className=" py-4 text-sm font-normal ml-2 w-auto h-auto pl-7 pr-7">
            <h5 className=" ">Name</h5>
          </th>
          <th className=" py-4 text-sm font-normal ml-2 w-auto h-auto  pl-7 pr-7">
            Category
          </th>
          <th className=" py-4 text-sm font-normal ml-2 w-auto h-auto  pl-7 pr-7">
            Status
          </th>
          <th className=" py-4 text-sm font-normal ml-2 w-auto h-auto  pl-7 pr-7">
            Type{" "}
          </th>
          {/* <th className=" py-4 text-sm font-normal ml-2 w-auto h-auto  pl-7 pr-7">
            Health{" "}
          </th> */}
          <th className=" py-4 text-sm font-normal ml-2 w-auto h-auto  pl-7 pr-7">
            Created At
          </th>
          <th className=" py-4 text-sm font-normal ml-2 w-auto h-auto  pl-7 pr-7">
            Action{" "}
          </th>
        </tr>
      </thead>
    </>
  );
}