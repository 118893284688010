import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function TabBar({ data }) {
  const PathName = useLocation().pathname;

  console.log("PathName:", PathName);

  return (
    <>
      {data.Logos && (
        <div className={`mt-10 ${data.width ? "w-11/12" : "w-5/6"} mx-auto`}>
          <div role="tablist" className="tabs tabs-bordered">
            <Link
              to={data.Links.link1}
              role="tab"
              className={`tab ${
                PathName.includes(data.Links.link1) && "tab-active text-black"
              }`}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo1}
                {data.span1}
              </div>
            </Link>

            <Link
              to={data.Links.link2}
              role="tab"
              className={`tab ${
                PathName.includes(data.Links.link2) && "tab-active text-black"
              }`}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo2}
                {data.span2}
              </div>
            </Link>

            <Link
              to={data.Links.link3}
              role="tab"
              className={`tab ${
                PathName.includes(data.Links.link3) && "tab-active text-black"
              }`}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo3}
                {data.span3}
              </div>
            </Link>

            <Link
              to={data.Links.link4}
              role="tab"
              className={`tab ${
                PathName.includes(data.Links.link4) && "tab-active text-black"
              }`}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo4}
                {data.span4}
              </div>
            </Link>

            <Link
              to={data.Links.link5}
              role="tab"
              className={`tab ${
                PathName.includes(data.Links.link5) && "tab-active text-black"
              }`}
            >
              <div className="flex gap-2 items-center justify-center">
                {data.Logos.logo5}
                {data.span5}
              </div>
            </Link>

            {data.span6 && (
              <Link
                to={data.Links.link6}
                role="tab"
                className={`tab ${
                  PathName.includes(data.Links.link6) && "tab-active text-black"
                }`}
              >
                <div className="flex gap-2 items-center justify-center">
                  {data.Logos.logo6}
                  {data.span6}
                </div>
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default TabBar;
