import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useGoogleShet = () => {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const googleSheet = async (data) => {
    try {
      setloading(true);
      const res = await axios.post("/api/sheets/promptForSheetUrl", data);
      toast.success("Google Sheets URL submitted successfully!");
      navigate("/contacts");
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloading(false);
    }
  };
  return { loading, googleSheet };
};

export default useGoogleShet;
