import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const SmallChip = styled(Chip)({
  fontSize: "7px", // Set font size
  padding: "2px", // Set padding
  borderWidth: "1px", // Set border width
  borderStyle: "solid", // Make sure border is solid
});
const handleStyle = { left: "220px", top: "20px" };

const InitialNode = ({ id, data, isConnectable }) => {
  const [keywords, setKeywords] = useState([]);
  return (
    <div className="bg-[#EAE6DF] border-[1px] p-1 border-green-500 text-[10px] rounded-lg w-36">
      <div className="flex items-center justify-between">Flow Start</div>
      <Handle
        type="source"
        position={Position.Top}
        style={handleStyle}
        isConnectable={isConnectable}
      />
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={keywords}
        onChange={(event, newValue) => setKeywords(newValue)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <SmallChip label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Enter keywords"
            sx={{
              "& .MuiInputBase-adornedEnd": {
                paddingRight: "10px !important",
              },
              "& .MuiOutlinedInput-root": {
                padding: "2px",
                fontSize: "7px",
                background: "white",
              },
              "& .css-1id8d84-MuiButtonBase-root-MuiChip-root": {
                padding: "0px",
                borderRadius: "5px !important",
                height: "20px !important",
              },
              "& .MuiChip-deleteIconFilledColorDefault": {
                fontSize: "10px !important",
              },
              "& .MuiAutocomplete-clearIndicator": {
                display: "none !important",
              },
            }}
          />
        )}
      />

      <input
        type="text"
        placeholder="Enter keywords"
        defaultValue={data.keywords || ""}
        className="w-full p-0.5 px-1 focus:outline-none rounded-sm bg-white text-black text-[7px]"
      />
      <input
        type="text"
        placeholder="Enter Regex"
        className="w-full mt-1 p-0.5 px-1 focus:outline-none rounded-sm bg-white text-black text-[7px]"
      />
      <button className="w-full p-0.5 text-[8px] mt-1  bg-white border border-gray-300 rounded hover:bg-gray-200">
        Choose Template
      </button>
      <button className="w-full p-0.5 text-[8px] mt-1 bg-white border border-gray-300 rounded hover:bg-gray-200">
        Choose Facebook Ad
      </button>
      <Handle type="source" position="bottom" id="a" />
    </div>
  );
};

export default memo(InitialNode);
