import React from "react";
import FlowTable from "../GlobalCmp/FlowTable";
import QuickGuilde from "../../../../components/QuickGuilde";
import useStoreDetails from "../../../../zustand/sopify/useMetaUserDetails";

const AbandonedCart = () => {
  const { storeLoading, storeDetails } = useStoreDetails();
  console.log("store details looks likes this :",storeDetails)

  const quickReplyObj = {
    width: "full",
    text1: "Easily configure Ecomm+ to automate your shopify messages.",
    text2:
      "Automatic Abandoned Cart Reminders, Order Notifications, COD recovery on whatsapp.",
    // link1: "How to get Access Token and API Secret Key",
    // link2: "How to setup automated shopify messages",
  };

  console.log(
    "abounded",
    storeDetails?.shopData?.abandonedCartFollowUpSettings?.isFlowRun
  );

  const flowtabdata = [
    {
      flowName: "Abandoned Cart 1",
      campaign: "N/A",
      status: storeDetails?.shopData?.abandonedCartSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.abandonedCartSettings?.isFlowRun || false,
      link: "abandonedCartSettings",
    },
    {
      flowName: "Abandoned Cart 2",
      campaign: "OrderFlow Cart 1: 19 Oct 12:23:38",
      status: storeDetails?.shopData?.abandonedCartFollowUpSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.abandonedCartFollowUpSettings?.isFlowRun ||
        false,
      link: "abandonedCartFollowUpSettings",
    },
    {
      flowName: "Abandoned Cart 3",
      campaign: "N/A",
      status: storeDetails?.shopData?.abandonedCartFollowUp1Settings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.abandonedCartFollowUp1Settings?.isFlowRun ||
        false,
      link: "abandonedCartFollowUp1Settings",
    },
    
  ];
  return (
    <div className="w-full min-h-[90vh] ">
      <QuickGuilde data={quickReplyObj} />
      {!storeLoading && <FlowTable data={flowtabdata} />}
    </div>
  );
};

export default AbandonedCart;
