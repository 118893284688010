import React from "react";
import SetUpMesssageCard from "../GlobalCmp/SetUpMesssageCard";

const setupMessages = () => {
  const steupmessage = [
    {
      title: "COD Order Cancel Status",
      cardBody: "Thanks for your response. We’ll get back to you soon.",
      Cardtype: "Text",
      imageUrl: "https://cdn-icons-png.flaticon.com/512/5867/5867363.png",
      templatename: "",
    },
    {
      title: "COD Order Confirmation",
      cardBody:
        "Hello [$FirstName],Thank you for purchasing from SHOPIFY_STORE_NAME. Your order has been created successfully. Since you’ve chosen Cash on Delivery, Click",
      Cardtype: "TEXT",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/3649/3649435.png",
    },
    {
      title: "COD Order Confirm Status",
      cardBody:
        "Thank you for your confirmation. Your package is now being processed for shipping. Have a great day! 😀",
      Cardtype: "TEXT",
      imageUrl: "https://cdn-icons-png.flaticon.com/512/5486/5486902.png",
    },
    {
      title: "Order Confirmed",
      cardBody:
        "🎉Hi [user] - Your order is confirmed 🎉Great news! We have received your order [y2834u9u1erbuh48r] amounting to [RS. $100].",
      Cardtype: "TEXT",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/6948/6948438.png",
      templatename: "shopify_order_confirmation",
    },
    {
      title: "Order Cancelled",
      cardBody:
        "Hi [$FirstName]!Your order [#$OrderNumber] amounting to [RS. $OrderAmount] has been cancelled. We’re sorry that this order didn’t work out for you,",
      Cardtype: "IMAGE",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/6797/6797021.png",
      templatename: "shopify_order_confirmation",
    },
    {
      title: "Abandoned Cart 1",
      cardBody:
        "Hi [$FirstName]! 👋Thank you for your order.Your payment of [RS. $OrderAmount] for an order created from the Princegrocery store is still pending.Check",
      Cardtype: "TEXT",
      imageUrl:
        "https://aisensy-project-media-library.s3.ap-south-1.amazonaws.com/IMAGE/6353da2e153a147b991dd812/43401_Abandoned%20Cart.png",
      templatename: "shopify_order_confirmation",
    },
    {
      title: "Abandoned Cart 2",
      cardBody:
        "Hi [$FirstName]!We noticed that the item(s) is still in your Cart. We have an amazing offer for you. 😍Use Code - [WCBACK10] and get [10%] off with Free Shipping all over India. 😉Products added: *",
      Cardtype: "TEXT",
      imageUrl: "https://cdn-icons-png.flaticon.com/128/891/891462.png",
      templatename: "shopify_order_confirmation",
    },
    {
      title: "Abandoned Cart 3",
      cardBody:
        "Hi [$FirstName]!We noticed that the item(s) is still in your Cart. Confused about what to order? 🤔We’ll keep your cart safe for you!Additionally, Use Code - [CODE29]",
      Cardtype: "TEXT",
      imageUrl: "https://cdn-icons-png.flaticon.com/512/891/891407.png",
      templatename: "shopify_order_confirmation",
    },
    {
      title: "COD Order Confirmation Followup",
      cardBody:
        "Hello {{1}},Thank you for purchasing from  . Your order has been created successfully. We noticed you haven't yet confirmed your order. Please tap on the button below to Confirm Order.",
      Cardtype: "TEXT",
      imageUrl:
        "https://aisensy-project-media-library.s3.ap-south-1.amazonaws.com/IMAGE/6383718694747141e2221ccb/9473785_825590.png",
      templatename: "shopify_order_confirmation",
    },
  ];

  return (
    <div>
      <div className="w-full text-xl p-5 font-medium bg-white">
        <div className="mt-4  p-3   rounded-md shadow py-6 px-8  mx-auto bg-white">
          <p className="p-2 text-xl  font-normal">Setup Messages</p>
          {/* <p></p> */}
          {/* <QuickGuilde data={quickReplyObj} /> */}
          <div className="text-[#6E6E6E] font-medium ml-3 flex relative ">
            <span className="text-sm"> Setup all messages in one click</span>
            <button className="btn- btn-sm bg-green-800 text-white rounded-md absolute  top-[-30px] right-3">
              Enable
            </button>
          </div>
          <div className="text-[#6E6E6E] font-medium ml-3 text-sm">
            A collection of messages best suited for your requirements has been
            compiled. You can choose to enable all the messages together or to
            carefully review each one before enabling the message flow
          </div>
        </div>
        <div className="mx-auto w-full flex justify-evenly flex-wrap">
          {steupmessage.map((data, index) => (
            <div key={index}>
              <SetUpMesssageCard
                title={data.title}
                cardBody={data.cardBody}
                Cardtype={data.Cardtype}
                imageUrl={data.imageUrl}
                templatename={data.templatename}
              />
            </div>
          ))}
        </div>                
      </div>
    </div>
  );
};

export default setupMessages;
