import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useCampaign = () => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const getCampaigns = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/whatsapp/details/campaigns");
      setCampaigns(res.data);

      console.log("camp data:", res.data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  //to refresh camp data
  const refreshCampData = () => {
    getCampaigns();
  };

  return { loading, campaigns, setCampaigns, refreshCampData };
};
//  this is will get all campain forparrticular
const useAnaCam = ({ id, category }) => {
  const [loading, setLoading] = useState(false);
  const [camAna, setCamAna] = useState({});

  const getAnaCam = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/whatsapp/details/campaigns/${id}?detail_of=${category}`
      );
      console.log(res.data);
      setCamAna(res.data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && category) {
      getAnaCam();
    }
  }, [id, category]);
  console.log("camp in hooks lokks likes :", camAna);

  return { loading, camAna };
};

export const useDeleteCamp = () => {
  const [loading, setLoading] = useState(false);

  const deleteCamp = async (id) => {
    try {
      setLoading(true);

      const res = await axios.delete(`/whatsapp/details/campaigns/${id}`);
      toast.success(res.data.message);
      return true;
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { loading, deleteCamp };
};
export { useCampaign, useAnaCam };
