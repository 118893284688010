import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { UseAuthContext } from "../context/AuthContext";
import { useEffect } from "react";

const useLogin = () => {
  const [loading, setLoading] = useState(false);

  const { setAuthUser } = UseAuthContext();

  const login = async ({ username, password }) => {
    const success = haldeInputError({ username, password });

    if (!success) return;

    setLoading(true);

    try {
      const res = await axios.post("/api/auth/login", {
        username,
        password,
      });

      const data = res.data;

      console.log(data);

      //set auth user in localstorage
      setAuthUser(data);
      localStorage.setItem("authUser", JSON.stringify(data));
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
      //set auth user in localstorage
      setAuthUser(null);
      localStorage.removeItem("authUser");
    } finally {
      setLoading(false);
    }
  };

  return { loading, login };
};

export default useLogin;

const haldeInputError = ({ username, password }) => {
  if (!username || !password) {
    toast.error("Please fill the fields!");
    return false;
  }

  return true;
};

export const useGetAuthDetails = () => {
  const [authLoading, setAuthLoading] = useState(false);
  const { setAuthUser } = UseAuthContext();

  const getAuthDetails = async () => {
    try {
      const res = await axios.get("/api/auth/sso");

      const data = res.data;
      //set auth user in localstorage
      setAuthUser(data);
      localStorage.setItem("authUser", JSON.stringify(data));
    } catch (error) {
      // toast.error(error.response?.data?.error || error.message);
      console.error(error.response?.data?.error || error.message);
    } finally {
      setAuthLoading(false);
    }
  };

  // useEffect(() => {
  //   getAuthDetails();
  // }, []);

  return { authLoading, getAuthDetails };
};
