import { Autocomplete, Box, FormControl, InputLabel, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { MenuItem } from "react-pro-sidebar";
import Graph from "./Graph";

export default function TabPage() {
  const [date, setDate] = useState("");
  console.log('value is ',date)

  const TimeStamps = [
    { label: "today", id: 1 },
    { label: "1 weekago", id: 2 },
    { label: "1 mounth", id: 3 },
  ];


  // const handleMenuChange = (event) => {
  //   setDate(event.target.value);
  // };
  const HandleMenuchange2 = (event, newValue) => {
    setDate(newValue.label);
    
  };
 

  return (
    <>
      <div>
        <Box sx={{ minWidth: 120 }}>
          {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">date</InputLabel>

            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={date}
              label="date"
              onChange={handleMenuChange}
            > 
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>
                <em>Ten</em>
              </MenuItem>
              <MenuItem value={20}>
                {" "}
                <em>Twenty</em>
              </MenuItem>
              <MenuItem value={30}>
                {" "}
                <em>Thirty</em>
              </MenuItem>
             
            </Select>
          </FormControl> */}

<Autocomplete
                  disablePortal
                  id="TemplateLanguage"
                  options={TimeStamps}
                  getOptionLabel={(option) => option.label}
                  sx={{
                    width: "25%",
                    marginTop: "10px",
                    background: "#F0F0F0",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px",
                    },
                    "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                      display: "none",
                    },
                    "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                      {
                        padding: "7.5px 4px 7.5px 13px",
                      },
                    "& .css-1b9mdof-MuiFormLabel-root-MuiInputLabel-root": {
                      color: "#A9A9A9",
                      fontSize: "medium",
                    },
                  }}
                  onChange={HandleMenuchange2}
                  value={date}
                  renderInput={(params) => (
                    <TextField {...params} label="Select date" />
                  )}
                />


        </Box>
      </div>

      <div className="w-full h-28 border-1 flex justify-evenly items-center mt-5">
        <div className="w-1/4  h-24 text-start">
          <p className="text-xs">Total MAU usage</p>
          <p className="text-xs">39</p>
        </div>
        <div className=" w-1/4  h-24">
          <p className="text-xs">Business created MAU</p>
          <p className="text-xs">0</p>
        </div>
        <div className="w-1/4  h-24">
          <p className="text-xs">User created MAU</p>
          <p className="text-xs">34</p>
        </div>
      </div>
      <Graph />
    </>
  );
}
