import React from "react";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

function GoBackHeader({ data }) {
  const navigate = useNavigate();
  const hadleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="w-full text-xl h-16 shadow font-medium bg-white flex justify-between items-center sticky top-0 z-50">
        <div className="flex justify-center gap-3 items-center">
          <div className="ms-5" onClick={hadleGoBack}>
            <IconButton aria-label="back">
              <KeyboardBackspaceIcon />
            </IconButton>
          </div>
          <div className="text-xl">{data.heading}</div>
        </div>
        {data.logo && (
          <figure className="w-10 p-1 border border-[#E8E8E8] rounded-md me-5 mb-0">
            <img src={data.logo} alt="shopify" />
          </figure>
        )}
      </div>
    </>
  );
}

export default GoBackHeader;
