import React, { useRef, useState } from "react";
import GlobalHeader from "../../components/GlobalComponets/GlobalHeader";
import QuickGuilde from "../../components/QuickGuilde";
import toast, { Toaster } from "react-hot-toast";

const ApiKey = () => {
  const data = {
    Title: "Generate API key",
  };

  const guideData = {
    text1:
      "API Key is the secret key used for authentication while making a request to our APIs.",
    text2:
      "You just need to generate an API key once and use it for communicating with our APIs.",
    link1: "Read API Docs",
  };

  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    toast.success("Successfully Copied!");
  }

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div>
        <GlobalHeader item={data} />
        <QuickGuilde data={guideData} />
        <div className="w-5/6 mx-auto rounded-md shadow-sm p-10 mt-10 bg-white">
          <div className="text-lg font-medium tracking-wide">API key</div>
          <textarea
            rows="3"
            ref={textAreaRef}
            value={
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1ZGVjYjNjZmYxZTgzMDlmNWMyMTY0ZCIsIm5hbWUiOiIxMFgiLCJhcHBOYW1lIjoiQWlTZW5zeSIsImNsaWVudElkIjoiNjVhNjI3NzJhODI2OTkyYmEwNWU0ZDU0IiwiYWN0aXZlUGxhbiI6Ik5PTkUiLCJpYXQiOjE3MDkwOTk4MzZ9.JFPTX-sHmRpPEo4PPdUPhkWEsiZraW3SczYFk_ZghAQ"
            }
            className="mt-5 w-full px-6 py-4 text-sm font-medium rounded-md bg-[#F0F0F0] textarea"
          ></textarea>
          <div className="flex justify-start items-center gap-5 mt-5">
            <div
              className="btn btn-sm border-none bg-[#0A474C] rounded-md text-white hover:bg-[#0A474C]  "
              onClick={copyToClipboard}
            >
              Click to copy
            </div>
            <div className="btn btn-sm border-none bg-[#0A474C] rounded-md text-white hover:bg-[#0A474C]  ">
              Regenerate Key
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiKey;
