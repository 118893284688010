import { create } from "zustand";

const useAllTemplateZus = create((set) => ({
  allTemplate: [],
  setAllTemplate: (allTemplate) => set({ allTemplate }),

  tempLoading: false,
  setTempLoading: (tempLoading) => set({ tempLoading }),
}));

export default useAllTemplateZus;
