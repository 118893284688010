import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TiFlowMerge } from "react-icons/ti";
import CampaignIcon from "@mui/icons-material/Campaign";
import { PersonOutlineOutlined } from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { CgLogOut } from "react-icons/cg";
import LogoutIcon from "@mui/icons-material/Logout";
import { BiLogOutCircle } from "react-icons/bi";
import useLogOut from "../../../hooks/useLogOut";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { useFetchAllTemp } from "../../../hooks/useManagetemplate";
import WebhookIcon from "@mui/icons-material/Webhook";
import useGetConversations from "../../../hooks/useGetConversations";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { Usegetagents } from "../../../hooks/useAgenst";
import { UseAuthContext } from "../../../context/AuthContext";
import useNewUnseenMessages from "../../../hooks/useNewUnseenMessages";
import useSessionActive from "../../../hooks/useSession";

const MyProSidebar = () => {
  //to fetch all templates when the user enter on dashboard
  useFetchAllTemp();
  //to fetch all contacts and conversations
  useGetConversations();
  // to get all agents
  Usegetagents();
  //to notification sound when msg from user
  useNewUnseenMessages();
  // to check session is active or not
  useSessionActive();

  const { loading, logOut } = useLogOut();

  const onHadleLogOut = async () => {
    await logOut();
  };

  const { authUser } = UseAuthContext();
  console.log("agenst auth data looks likes this :", authUser);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { collapseSidebar, toggleSidebar, collapsed } = useProSidebar();
  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        color: "white",
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar breakPoint="md" backgroundColor={colors.primary[400]}>
        <Menu iconshape="square">
          <MenuItem
            style={{
              margin: "10px 0 10px 0",
              color: "009f55",
            }}
            icon={collapsed ? <MenuOutlinedIcon /> : undefined}
            onClick={() => {
              collapseSidebar();
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt="20px"
              >
                {/* <Typography variant="h3">KoreGrow</Typography> */}
                <IconButton onClick={() => toggleSidebar()}>
                  {/* <CloseOutlinedIcon /> */}
                  <img className="w-16 mx-auto" src="/assets/Logo.png" alt="" />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && <Box></Box>}

          {authUser?.user_Role === "Agent" ? (
            <Box paddingLeft={collapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
               variant="h6"
               color={colors.grey[700]}
               sx={{ m: "15px 0 5px 20px" }}
             >
               Data
             </Typography> */}
              <Item
                title="Live Chat"
                to="/livechat"
                icon={<ForumOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* History */}
              {/* <Item
               title="History"
               to="/history"
               icon={<RestoreOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="Contacts"
               to="/contacts"
               icon={<ContactsOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}

              {/* <Item
               title="Campaigns"
               to="/campaigns"
               icon={<CampaignIcon />}
               selected={selected}
               setSelected={setSelected}
             />
  */}
              {/* <Typography
               variant="h6"
               color={colors.grey[700]}
               sx={{ m: "15px 0 5px 20px" }}
             >
               Pages
             </Typography> */}
              {/* <Item
               title="Profile"
               to="/profile"
               icon={<PersonOutlineOutlined />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="Calendar"
               to="/calendar"
               icon={<CalendarTodayOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="FAQ Page"
               to="/faq"
               icon={<HelpOutlineOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}

              {/* <Typography
               variant="h6"
               color={colors.grey[700]}
               sx={{ m: "15px 0 5px 20px" }}
             >
               Manage
             </Typography> */}
              {/* <Item
               title="Create Template"
               to="/template"
               icon={<PolylineOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="Opt-In/Out"
               to="/optSystem"
               icon={<FilterAltOffIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="Agent"
               to="/Agent"
               icon={<FaUsersBetweenLines className="text-2xl" />}
               selected={selected}
               setSelected={setSelected}
             /> */}

              {/* <Item
               title="User Concern"
               to="/UserConcerns"
               icon={<VerifiedUserIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* 
             <Item
               title="Biling & Usage"
               to="/biling"
               icon={<AccountBalanceWalletIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="Flow"
               to="/flow"
               icon={<TiFlowMerge />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="Integration "
               to="/integration"
               icon={<WebhookIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="Analytics"
               to="/analytics"
               icon={<QueryStatsOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="API Key"
               to="/apikey"
               icon={<VpnKeyIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              {/* <Item
               title="API Key"
               to="/apikey"
               icon={<VpnKeyIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
              <MenuItem
                disabled={loading}
                onClick={onHadleLogOut}
                style={{ color: colors.grey[100] }}
                icon={<LogoutIcon />}
              >
                {loading ? (
                  <span className="loading loading-dots"></span>
                ) : (
                  <Typography>Log Out</Typography>
                )}
              </MenuItem>
            </Box>
          ) : (
            <Box paddingLeft={collapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
              variant="h6"
              color={colors.grey[700]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography> */}
              <Item
                title="Live Chat"
                to="/livechat"
                icon={<ForumOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* History */}
              <Item
                title="Contacts"
                to="/contacts"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Campaigns"
                to="/campaigns"
                icon={<CampaignIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
              variant="h6"
              color={colors.grey[700]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography> */}
              <Item
                title="Profile"
                to="/profile"
                icon={<PersonOutlineOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
              {/* <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

              {/* <Typography
              variant="h6"
              color={colors.grey[700]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Manage
            </Typography> */}
              <Item
                title="Manage Template"
                to="/template/message/Marketing"
                icon={<PolylineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Opt-In/Out"
                to="/optSystem"
                icon={<FilterAltOffIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Agent"
                to="/Agent"
                icon={<FaUsersBetweenLines className="text-2xl" />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Item
              title="User Concern"
              to="/UserConcerns"
              icon={<VerifiedUserIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
              {/* 
            <Item
              title="Biling & Usage"
              to="/biling"
              icon={<AccountBalanceWalletIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
              <Item
                title="Flow"
                to="/flow"
                icon={<TiFlowMerge />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Integration "
                to="/integration"
                icon={<WebhookIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Analytics"
                to="/analytics"
                icon={<QueryStatsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Item
              title="API Key"
              to="/apikey"
              icon={<VpnKeyIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
              {/* <Item
              title="API Key"
              to="/apikey"
              icon={<VpnKeyIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
              <MenuItem
                disabled={loading}
                onClick={onHadleLogOut}
                style={{ color: colors.grey[100] }}
                icon={<LogoutIcon />}
              >
                {loading ? (
                  <span className="loading loading-dots"></span>
                ) : (
                  <Typography>Log Out</Typography>
                )}
              </MenuItem>
            </Box>
          )}

          {/* <button
            disabled={loading}
            className="btn w-full border-none rounded-none bg-[#122D30] text-white font-bold p-2 pe-20"
            onClick={onHadleLogOut}
          >
            {loading ? (
              <span className="loading loading-dots"></span>
            ) : (
              <>
                <span className="text-xl text-red-500">
                  <BiLogOutCircle />
                </span>
                Log Out
              </>
            )}
          </button> */}
        </Menu>
      </Sidebar>
    </Box>
  );
};

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const PathName = useLocation().pathname;

  const isActiveTab = to !== "/" ? PathName.includes(to) : PathName === to;

  return (
    <MenuItem
      // active={selected === title}
      active={isActiveTab}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};
export default MyProSidebar;
