import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";

const useConnectCatelog = () => {
  const [conLoad, setConLoad] = useState(false);
  const [catalogData, setCatalogData] = useState();

  const connectCatalog = async (catalog_Id) => {
    if (!catalog_Id) {
      toast.error("Invalid catalog ID.");
      return;
    }
    try {
      setConLoad(true);
      console.log("useConnectCatelog:", catalog_Id);

      const res = await axios.post("/api/catalog/connectCatalog", {
        catalog_Id,
      });
      setCatalogData(res.data);
      console.log(res.data);

      toast.success("catalog connected!");
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
      console.log("error in useConnectCatelog:", error.response);
    } finally {
      setConLoad(false);
    }
  };

  return { conLoad, catalogData, connectCatalog };
};

// const useDisCatelog = () => {
//   const [disLoad, setDisLoad] = useState(false);
//   const [catalogData, setCatalogData] = useState();

//   const disCatalog = async (catalog_Id) => {
//     try {
//       console.log("useDisCatelog:", catalog_Id);

//       setDisLoad(true);
//       const res = await axios.delete(
//         `/api/catalog/disConnectCatalog/${catalog_Id}`
//       );
//       setCatalogData(res.data);
//       toast.success("catalog connected!");
//     } catch (error) {
//       toast.error(error.response?.data?.error || error.message);
//       console.log("error in useDisCatelog:", error.response);
//     } finally {
//       setDisLoad(false);
//     }
//   };

//   return { disLoad, catalogData, disCatalog };
// };

const useDisCatelog = () => {
  const [disLoad, setDisLoad] = useState(false);
  const [catalogData, setCatalogData] = useState(null);

  const disCatalog = useCallback(async (catalog_Id) => {
    if (!catalog_Id) {
      toast.error("Invalid catalog ID.");
      return;
    }
    try {
      console.log("useDisCatelog:", catalog_Id);
      setDisLoad(true);
      const res = await axios.delete(
        `/api/catalog/disConnectCatalog/${catalog_Id}`
      );
      setCatalogData(res.data);
      toast.success("Catalog disconnected!");
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setDisLoad(false);
    }
  }, []);

  return { disLoad, catalogData, disCatalog };
};

const useGetCatelog = () => {
  const [getLoad, setGetLoad] = useState(false);
  const [getcatalogData, setgetCatalogData] = useState(null);

  const getCatalog = useCallback(async () => {
    try {
      setGetLoad(true);
      const res = await axios.get(`/api/catalog/getConnectCatalog`);
      setgetCatalogData(res.data);
    } catch (error) {
    } finally {
      setGetLoad(false);
    }
  }, []);

  useEffect(() => {
    getCatalog();
  }, []);

  return { getLoad, getcatalogData, getCatalog };
};

export { useDisCatelog, useConnectCatelog, useGetCatelog };
