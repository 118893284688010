import { Handle, Position } from "reactflow";
import DeleteNode from "../deleteNode/DeleteNode";
import { MdPermMedia } from "react-icons/md";

const handleStyle = { left: "220px", top: "20px" };
const handle1Style = { left: "0px" };

function MediaCustomNode({ id, data, isConnectable }) {
  return (
    <div
      className="bg-white shadow w-60 p-2 rounded-lg nowheel"
      style={{ overflow: "auto" }}
    >
      <DeleteNode id={id} />
      <Handle
        type="source"
        position={Position.Top}
        style={handleStyle}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        id="c"
        position={Position.Left}
        style={handle1Style}
        isConnectable={isConnectable}
      />
      <div className="">
        <label className="bg-[#E9EDEF] rounded-md py-1 px-2 mb-2 text-[#008069] flex items-center">
          <div className="font-bold text-base">
            <MdPermMedia />
          </div>
          <div className="ms-2">Media + Buttons</div>
        </label>
        <div className="p-1 rounded-md bg-[#EAE6DF]">
          <div className="w-full rounded-md overflow-hidden mb-1">
            <div className="w-full h-32  border-none bg-white image-full overflow-hidden">
              <img src="/assets/floraltop.jpg" alt="217px x 128px" />
            </div>
            <div className="w-full py-2 bg-white text-center">
              Welcome to Company Name select from the options below
            </div>
          </div>
          <div className="w-full text-base py-2 border border-[#008069] rounded-md text-[#387ADF] mb-1 bg-white text-center">
            Shop Now
          </div>
          <div className="w-full text-base py-2 border border-[#008069] rounded-md text-[#387ADF] mb-1 bg-white text-center">
            Track Order
          </div>
          <div className="w-full text-base py-2 border border-[#008069] rounded-md text-[#387ADF] mb-1 bg-white text-center">
            Talk to an Agent
          </div>

          <div className="btn btn-sm w-full mb-1 text-black border-none bg-white text-center hover:bg-white">
            +Add Keyword
          </div>
          <div className="btn btn-sm w-full mt-1 text-black border-none bg-white text-center hover:bg-white">
            Choose Template
          </div>
        </div>
      </div>

      {/* <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={handleStyle}
        isConnectable={isConnectable}
      /> */}
    </div>
  );
}

export default MediaCustomNode;
