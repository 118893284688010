import { useCallback, useEffect, useState } from "react";
import "./flow.css";
import ReactFlow, {
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MiniMap,
  Controls,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";

import TopbarFlow from "./components/topbar/TopbarFlow";
import Sidebar from "./components/sidebar/Sidebar";
import TBCustomNode from "./components/customNode's/TBCustomNode";
import CustomEdge from "./components/customEdge/CustomEdge";
import MediaCustomNode from "./components/customNode's/MediaCustomNode";
import ListCustomNode from "./components/customNode's/ListCustomNode";
import SingleCustomNode from "./components/customNode's/SingleCustomNode";
import MultiPCustomNode from "./components/customNode's/MultiPCustomNode";
import ContactCostomNode from "./components/customNode's/ContactHumanCustomNode";
import InitialNode from "./components/customNode's/InitialNode";

const initialNodes = [
  {
    id: "1",
    type: "initialNode",
    data: { label: "Flow Start", keywords: "test" },
    position: { x: 250, y: 5 },
  },
];

const initialEdges = [];

const nodeTypes = {
  initialNode: InitialNode,
  textUpdater: TBCustomNode,
  mediaUpdater: MediaCustomNode,
  listUpdater: ListCustomNode,
  singleProductUpdater: SingleCustomNode,
  multiProductUpdater: MultiPCustomNode,
  contactUpdater: ContactCostomNode,
};

function Flow() {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  useEffect(() => {
    const savedNodes = JSON.parse(localStorage.getItem("flowNodes") || "[]");
    const savedEdges = JSON.parse(localStorage.getItem("flowEdges") || "[]");
    setNodes(savedNodes);
    setEdges(savedEdges);
  }, []);

  const saveChanges = () => {
    localStorage.setItem("flowNodes", JSON.stringify(nodes));
    localStorage.setItem("flowEdges", JSON.stringify(edges));
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onNodeInputChange = useCallback((id, name, value) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, [name]: value } }
          : node
      )
    );
  }, []);

  const addNode = useCallback(
    (type, data = {}) => {
      const newNode = {
        id: (nodes.length + 1).toString(),
        type: type,
        position: { x: 500, y: 500 },
        data: {
          label: `${type} Node`,
          ...data,
          onInputChange: onNodeInputChange,
        },
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [nodes, setNodes, onNodeInputChange]
  );

  const defaultEdgeOptions = {
    animated: true,
    style: { stroke: "#008069" },
    type: "custom",
  };

  const edgeTypes = {
    custom: CustomEdge,
  };

  const connectionLineStyle = { stroke: "#008069" };

  return (
    <div className="h-[100vh] w-full relative flex">
      <Sidebar onAddNode={addNode} />
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onConnect={onConnect}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        connectionLineStyle={connectionLineStyle}
        edgeTypes={edgeTypes}
        fitView
      >
        <TopbarFlow saveChanges={saveChanges} />
        <Background />
        <Controls />
        <MiniMap zoomable pannable />
      </ReactFlow>
    </div>
  );
}

export default Flow;

// import React, { useState } from "react";
// import FlowDataComponent from "./FlowDataComponent";

// const Flow = () => {
//   const [senderId, setSenderId] = useState("");
//   const [isChatFlowEnabled, setIsChatFlowEnabled] = useState(false);
//   const [flowData, setFlowData] = useState([
//     { keyWords: [""], resType: "text", resSend: "" },
//   ]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission here
//     const payload = {
//       senderId,
//       isChatFlowEnabled,
//       flowData,
//     };
//     console.log("Form data", payload);
//   };

//   return (
//     <form onSubmit={handleSubmit} className="mx-auto w-11/12 my-4 ">
//       <div className="bg-white rounded-lg shadow-md p-6 mt-16">
//         <div className="flex items-center justify-between">
//           <div>
//             <h2 className="text-lg font-semibold text-gray-800">
//               Chat Flow Setting
//             </h2>
//             <p className="text-sm text-gray-600 mt-1">
//               Enable Chat Flow if you don't wish to send auto reply to contacts
//             </p>
//           </div>
//           <label className="relative inline-flex items-center cursor-pointer">
//             <input
//               type="checkbox"
//               className="sr-only peer bg-white"
//               checked={isChatFlowEnabled}
//               onChange={() => setIsChatFlowEnabled(!isChatFlowEnabled)}
//             />
//             <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
//           </label>
//         </div>
//       </div>

//       <FlowDataComponent flowData={flowData} setFlowData={setFlowData} />

//       <button
//         type="submit"
//         className="px-4 py-2 bg-blue-500 text-white rounded-lg mt-4"
//       >
//         Submit
//       </button>
//     </form>
//   );
// };

// export default Flow;
