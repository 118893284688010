import React from "react";
import TableHeader from "../../components/template/TableHeader";
import List from "../../components/template/List";

function Approval() {
  const listDetails = [
    {
      row1: "sample_template_message",
      row2: "UTILITY",
      row3: "APPROVED",
      row4: "Text",
      row5: "high",
      row6: "june",

      boxContent1:
        "The first alwys special like your first salary,your first car & your first date",
      boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
      boxContent3:
        " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
      boxContent4: "just use code :{{3}}",
      boxContent5: "reply Stop to unsubscribe",
      id: "1",
    },
    {
      row1: "sample_template_message",
      row2: "UTILITY",
      row3: "APPROVED",
      row4: "Text",
      row5: "high",
      row6: "june",

      boxContent1:
        "The first alwys special like your first salary,your first car & your first date",
      boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
      boxContent3:
        " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
      boxContent4: "just use code :{{3}}",
      boxContent5: "reply Stop to unsubscribe",
      id: "2",
    },
    {
      row1: "sample_template_message",
      row2: "UTILITY",
      row3: "APPROVED",
      row4: "Text",
      row5: "high",
      row6: "june",

      boxContent1:
        "The first alwys special like your first salary,your first car & your first date",
      boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
      boxContent3:
        " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
      boxContent4: "just use code :{{3}}",
      boxContent5: "reply Stop to unsubscribe",
      id: "3",
    },
    {
      row1: "sample_template_message",
      row2: "UTILITY",
      row3: "APPROVED",
      row4: "Text",
      row5: "high",
      row6: "june",

      boxContent1:
        "The first alwys special like your first salary,your first car & your first date",
      boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
      boxContent3:
        " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
      boxContent4: "just use code :{{3}}",
      boxContent5: "reply Stop to unsubscribe",
      id: "4",
    },
    {
      row1: "sample_template_message",
      row2: "UTILITY",
      row3: "APPROVED",
      row4: "Text",
      row5: "high",
      row6: "june",

      boxContent1:
        "The first alwys special like your first salary,your first car & your first date",
      boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
      boxContent3:
        " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
      boxContent4: "just use code :{{3}}",
      boxContent5: "reply Stop to unsubscribe",
      id: "5",
    },
    {
      row1: "sample_template_message",
      row2: "UTILITY",
      row3: "APPROVED",
      row4: "Text",
      row5: "high",
      row6: "june",

      boxContent1:
        "The first alwys special like your first salary,your first car & your first date",
      boxContent2: "Do you know what's special for us? YOUR FIRST ORDER",
      boxContent3:
        " We want to make it speical for youtoo get a Flat Discount of {{1 on your first order Worth {{2}} or more , along with Free Delivary",
      boxContent4: "just use code :{{3}}",
      boxContent5: "reply Stop to unsubscribe",
      id: "6",
    },
  ];
  return (
    <>
      <div className="container mx-auto">
        <table className="w-11/12  my-10 rounded-md mx-auto ">
          <TableHeader />

          {listDetails.map((data) => (
            <List
              key={data.id}
              row1={data.row1}
              row2={data.row2}
              row3={data.row3}
              row4={data.row4}
              row5={data.row5}
              row6={data.row6}
              boxContent1={data.boxContent1}
              boxContent2={data.boxContent2}
              boxContent3={data.boxContent3}
              boxContent4={data.boxContent4}
              boxContent5={data.boxContent5}
            />
          ))}
        </table>
      </div>
    </>
  );
}

export default Approval;
