import React, { useState, useEffect } from "react";
import Papa from "papaparse";

const CsvDisplay = ({ url }) => {
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    const fetchCSV = async () => {
      const response = await fetch(url);
      const data = await response.text();
      Papa.parse(data, {
        complete: (result) => {
          setCsvData(result.data);
        },
      });
    };

    fetchCSV();
  }, [url]);

  return (
    <div className="overflow-auto">
      <table className="table-auto border-collapse w-full">
        <thead>
          <tr>
            {csvData[0]?.map((header, index) => (
              <th key={index} className="border p-2">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {csvData.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="border p-2">{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CsvDisplay;
