import { create } from "zustand";

const useStoreDetails = create((set) => ({
  storeDetails: {},
  setStoreDetails: (storeDetails) => set({ storeDetails }),

  storeLoading: false,
  setStoreLoading: (storeLoading) => set({ storeLoading }),
}));

export default useStoreDetails;
