import React from "react";
import "./liveChat.css";
import SideBar from "../../components/liveChat/sidebar/SideBar";
import MessageContainer from "../../components/liveChat/messages/MessageContainer";
import ClientProfile from "../../components/liveChat/clientProfile/ClientProfile";

const index = () => {
  return (
    <div className="flex sm:h-[450px] md:h-[100vh] w-full overflow-hidden bg-clip-padding backdrop-filter backdrop-blur-lg">
      <SideBar />
      <MessageContainer />
      <ClientProfile />
    </div>
  );
};

export default index;
