import axios from "axios";
import { useState, useEffect } from "react";
import toast, { ToastBar } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useZusConversation from "../zustand/useZusConversation";
import useAllTemplateZus from "../zustand/useAllTemplate";

//create template
const useCreateTemplate = () => {
  const [istemplateresponse, setTemplateresponse] = useState(null);
  const [loadTemplate, setLoadTemplate] = useState(false);

  const { allTemplate, setAllTemplate } = useAllTemplateZus();

  const navigate = useNavigate();

  const templateCreate = async ({
    templateName,
    headertext,
    languageCode,
    category,
    format,
    headerText,
    templateBody,
    templatefooter,
    body_text,
    UrlButtons,
    Phone_Number,
    QUICKREPLYtext,
    cardData,
    cardformat
    
  }) => {
    try {

    
      setLoadTemplate(true);
    let totalbuttoncount = UrlButtons.length + Phone_Number.length + Phone_Number.length
   console.log("format looks likes :",format)
    if (format === 'CAROUSEL' && totalbuttoncount < 0  ) {
       toast.error("atleast one button is required in carousel template ")
       return;
    }
      const response = await axios.post("/api/message/template", {
        templateName,
        headertext,
        languageCode,
        category,
        format,
        templateBody,
        headerText,
        templatefooter,
        body_text,
        UrlButtons,
        Phone_Number,
        QUICKREPLYtext,
        cardData,
        cardformat
      });
      const data = await response.data;

      console.log("reposne data is:", data);
      console.log("reposne data is:", data.message);

      //add new template in template list
      const newtemplate = data.template;
      setAllTemplate([...allTemplate, newtemplate]);

      toast.success(data.message); // Show success message

      // redirect to template page when the req res will success!
      if (newtemplate.templateStatus === "REJECTED") {
        navigate("/template/message/action");
      } else if (newtemplate.templateStatus === "PENDING") {
        navigate("/template/message/pending");
      } else if (
        newtemplate.templateStatus === "APPROVED" &&
        newtemplate.configData.category === "MARKETING"
      ) {
        navigate("/template/message/");
      } else if (
        newtemplate.templateStatus === "APPROVED" &&
        newtemplate.configData.category === "UTILITY"
      ) {
        navigate("/template/message/Utility");
      } else if (
        newtemplate.templateStatus === "APPROVED" &&
        newtemplate.configData.category === "AUTHENTICATION"
      ) {
        navigate("/template/message/Authentication");
      }
    } catch (error) {
      // toast.error(error.response?.data?.error || error.message); // Show error message
      const errorMessage =
        error.response?.data?.error?.error_user_msg ||
        error.response?.data?.error ||
        error.message;

      toast.error(errorMessage); // Show detailed error message
    } finally {
      setLoadTemplate(false);
    }
  };

  return { loadTemplate, istemplateresponse, templateCreate };
};

// useGetTemplate.js
const useFetchTemplates = (category, status) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `/api/message/mytemplate?category=${category}&status=${status}`
        );
        setTemplates(response.data);
      } catch (error) {
        toast.error(error.response?.data?.error || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, [category, status]);

  return { templates, loading };
};

//to fetch all template
export const useFetchAllTemp = () => {
  const { setTempLoading, setAllTemplate } = useAllTemplateZus();

  const fetchAllTemp = async () => {
    try {
      setTempLoading(true);

      const res = await axios.get("/api/message/alltemplate");
      setAllTemplate(res.data);
      console.log("template:", res.data);
    } catch (error) {
      toast.error(error.data.error);
    } finally {
      setTempLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTemp();
  }, []);

  return {};
};

// fetch status of the template
const useFetchStatus = (status) => {
  const [isstatus, setstatus] = useState([]);
  const [load, setloader] = useState(false);

  useEffect(() => {
    const fetchStatusTemplates = async () => {
      try {
        setloader(true);
        const response = await axios.get(
          `/api/message/mytemplate?status=${status}`
        );
        setstatus(response.data);
      } catch (error) {
        toast.error(error.response?.data?.error || error.message);
      } finally {
        setloader(false);
      }
    };
    fetchStatusTemplates();
  }, [status]);
  return { isstatus, load };
};

// const useDeleteTemplate = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const { allTemplate, setAllTemplate } = useAllTemplateZus();

//   const deleteTemplate = async ({ id, name }) => {
//     if (!id) {
//       // Assuming templatename is not required
//       return toast.error("something went wrong!");
//     }
//     console.log("data in web hooks:", id, name);
//     try {
//       setIsLoading(true);
//       const response = await axios.delete(`/api/message/deleteTemplate/`, {
//         params: {
//           id,
//           name,
//         },
//       });
//       const data = await response.data;

//       //old method to update all template
//       // const newData = allTemplate.filter((template) => template.id !== id);
//       // setAllTemplate(newData);

//       // Update the allTemplate state by removing the deleted template
//       console.log("allTemplate:", allTemplate);

//       if (Array.isArray(allTemplate)) {
//         const newData = allTemplate.filter((template) => template.id !== id);
//         setAllTemplate(newData);
//         toast.success(data);
//       } else {
//         console.error("allTemplate is not an array!");
//       }
//     } catch (error) {
//       toast.error(
//         error.response?.data?.error ||
//           "An error occurred while deleting the template"
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return {
//     isLoading,
//     deleteTemplate,
//   };
// };

const useDeleteTemplate = () => {
  const [isDeleted, setIsDeleted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { allTemplate, setAllTemplate } = useAllTemplateZus();

  const deleteTemplate = async ({ id, name }) => {
    if (!id || !name) {
      // Assuming templatename is not required
      return toast.error("something went wrong!");
    }
    console.log("data in web hooks:", id, name);
    try {
      setIsLoading(true);
      const response = await axios.delete(`/api/message/deleteTemplate/`, {
        params: {
          id,
          name,
        },
      });
      const data = await response.data;
      console.log(data);

      //old method to update all template
      // const newData = allTemplate.filter((template) => template.id !== id);
      // setAllTemplate(newData);

      // Update the allTemplate state by removing the deleted template
      console.log("allTemplate:", allTemplate);

      if (Array.isArray(allTemplate)) {
        const newData = allTemplate.filter((template) => template.id !== id);
        setAllTemplate(newData);
        toast.success("template deteted successfully");
      } else {
        console.error("allTemplate is not an array!");
      }

      setIsDeleted(true); // Set isDeleted to true on successful deletion (optional)
    } catch (error) {
      toast.error(
        error.response?.data?.error ||
          "An error occurred while deleting the template"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isDeleted,
    isLoading,
    deleteTemplate,
  };
};

// this hooks will refresh data from backend and meta
const useRefreshTemplates = () => {
  const [isloader, setloader] = useState(false);
  const { setAllTemplate } = useAllTemplateZus();

  const refreshData = async () => {
    try {
      setloader(true);
      const resposne = await axios.get("/api/message/updatedstatus");
      const data = resposne.data;

      // console.log("refreshData :", data);

      toast.success("Templates updated successfully");
      setAllTemplate(data);
      // setrefresh
    } catch (error) {
      console.log("error :", error);

      toast.error(error.response.data || error.message);
    } finally {
      setloader(false);
    }
  };

  return { isloader, refreshData };
};

// this hook is used in  buck message from:sagar
const useBulkMessage = () => {
  const [isSendTemplate, setTemplate] = useState("");
  const [isloader, setloader] = useState("");

  const sendBulkMessage = async ({
    id,
    contact,
    mediaurl,
    latitude,
    longitude,
    address,
    name,
    campname,
    parameters,
    conatactId,
  }) => {
    console.log("id looks like :", id);
    console.log("media dATA IN HOOKS looks likes this :",mediaurl)
    console.log("latitude looks like :", latitude);
    console.log("media dATA IN HOOKS looks likes this :",mediaurl)
    try {
      setloader(true);
      const resposne = await axios.post("/api/message/bulkmessage", {
        id,
        contact,
        mediaurl,
        campname,
        parameters,
        conatactId,
        latitude,
        longitude,
        address,
        name,
      });
      const data = resposne.data;
      if (data.error) {
        toast.error(data.error); // Show error message from API
      } else {
        toast.success(data.message); // Show success message
      }

      console.log("status looks like this :", data);
    } catch (error) {
      // toast.error(error.response?.data?.error || error.message); // Show error message
      const errorMessage =
        error.response?.data?.error?.error_user_msg ||
        error.response?.data?.error ||
        error.message;
      console.log("error object looks like this :", errorMessage);
      toast.error(errorMessage); // Show detailed error message
    } finally {
      setloader(false);
    }
  };
  sendBulkMessage();

  return { isSendTemplate, isloader, sendBulkMessage };
};

//this hooks helps in single template message from:sagar bhatia
const useSingleMessage = () => {
  const [isSendTemplate, setTemplate] = useState("");
  const [isloader, setloader] = useState("");
  const { messages, setMessages, selectedConversation } = useZusConversation();

  const sendSinglemesssage = async ({
    id,
    contact,
    mediaurl,
    latitude,
    longitude,
    name,
    address,
    campname,
    parameters,
    conatactId,
  }) => {
    try {
      console.log(
        " data inside hooks looks likes this : ",
        id,
        contact,
        mediaurl,
        campname,
        parameters,
        conatactId,
        latitude,
        longitude,
        name,
        address
      );
      setloader(true);
      const resposne = await axios.post(
        "/api/client/messages/sendmessageTemplate",
        {
          id,
          contact,
          mediaurl,
          campname,
          parameters,
          conatactId,
          latitude,
          longitude,
          name,
          address,
        }
      );
      const data = resposne.data;

      setMessages([...messages, data]);

      console.log("status looks like this :", data);
      if (data) {
        toast.success(`message sent sucessfully..`);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.error ||
          "An error occurred while deleting the template"
      );
    } finally {
      setloader(false);
    }
  };
  sendSinglemesssage();

  return { isSendTemplate, isloader, sendSinglemesssage };
};

//this hook helps to save auto repsonse template from : sagar bhatia
const useAutoResponse = () => {
  const [isConfiguration, setConfiguration] = useState("");
  const [isloading, setloading] = useState(false);

  const newConfiguration = async ({ id, mediaurl, parameters }) => {
    try {
      setloading(true);
      const resposne = await axios.post(
        "/api/client/messages/saveconfiguration",
        {
          id,
          mediaurl,
          parameters,
        }
      );

      const data = await resposne.data;

      if (data) {
        toast.success(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error("error in creating configuration", error);
    } finally {
      setloading(false);
    }
  };
  newConfiguration();
  return { isloading, isConfiguration, newConfiguration };
};
//this hook help display userconcern template
const useShowautoResponseTemplate = () => {
  const [isData, setData] = useState(null); // Initialize with null
  const [isloader, seloader] = useState(false);

  const DisplayautoResponse = async () => {
    try {
      seloader(true);

      const response = await axios.get(
        "/api/client/messages/template/Getsavedconfigdata"
      );
      const data = response.data;

      if (data && !data.error) {
        setData(data);
      } else {
        toast.error(data?.error || "Unexpected error occurred");
      }
    } catch (error) {
      toast.error("Something went wrong...");
    } finally {
      seloader(false);
    }
  };

  useEffect(() => {
    DisplayautoResponse();
  }, []);

  const refreshData = () => {
    DisplayautoResponse();
  };

  return { isloader, isData, refreshData };
};

export {
  useCreateTemplate,
  useFetchTemplates,
  useFetchStatus,
  useDeleteTemplate,
  useBulkMessage,
  useSingleMessage,
  useAutoResponse,
  useShowautoResponseTemplate,
  useRefreshTemplates,
};
