import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { MdVerified } from "react-icons/md";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OtpInput from "../../components/otp/OtpInput";
import useGenerateOtp from "../../hooks/useGenerateOtp";
import useChangePass from "../../hooks/useChangePass";
import { Link } from "react-router-dom";

function OTP() {
  const [inputs, setInputs] = useState({
    username: "",
    otp: "",
    password: "",
    confirmPass: "",
  });

  const { loading, otpSendSuccess, generateOtp } = useGenerateOtp();
  const hadleOtpSend = async () => {
    await generateOtp(inputs.username);
  };

  const { loading1, status, changePassword } = useChangePass();
  const hadleChangePassword = async () => {
    await changePassword(inputs);
    if (status) {
    }
    // setInputs({
    //   // username: "",
    //   otp: "",
    //   password: "",
    //   confirmPass: "",
    // });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const onOtPSubmit = (otp) => {
    console.log("logged in succesfull", otp);
    setInputs({ ...inputs, otp: otp });
  };

  return (
    <>
      <div className=" mx-auto h-screen bg-gray-100 flex  justify-between">
        <div className="w-[42%] bg-[#EBF5F3]">
          <div className="pt-14 ps-10 text-4xl font-semibold tracking-wider">
            <span className="text-green-500">K</span>
            <span className="text-gray-700">U</span>
            <span className="text-green-500">K</span>
            <span className="text-gray-700">AA</span>
          </div>
          <div className="mt-24 ps-16">
            <h1 className="text-[#0A474C] text-[28px] font-semibold">
              How to Change Your Password:
            </h1>
            <div className="flex mt-6 items-center">
              <div className="text-xl text-green-400">
                <MdVerified />
              </div>
              <div className="font-[400] text-[16px] ps-4">
                don't share OTP to others
              </div>
            </div>
            <div className="flex mt-4 items-center">
              <div className="text-xl text-green-400">
                <MdVerified />
              </div>
              <div className="font-[400] text-[16px] ps-4">
                Verify with OTP sent to [Phone/Email] (depending on chosen
                method).
              </div>
            </div>
            <div className="flex mt-4 items-center">
              <div className="text-xl text-green-400">
                <MdVerified />
              </div>
              <div className="font-[400] text-[16px] ps-4">
                8+ characters, mix of upper/lower, numbers & symbols
              </div>
            </div>
            <div className="flex mt-4 items-center">
              <div className="text-xl text-green-400">
                <MdVerified />
              </div>
              <div className="font-[400] text-[16px] ps-4">
                Verify with OTP & Save
              </div>
            </div>
          </div>
          <div className="mt-[14vh] text-center text-base text-gray-500">
            <h3>Trusted by 1500+ Brands</h3>
            <div className="flex justify-center">
              <img
                src="/assets/Shopify-Logo.png"
                width={100}
                height={50}
                alt=""
              />
              <img
                className="h-9 w-36 mt-4 ms-4"
                src="/assets/shiprocket.png"
                alt=""
              />
              <img
                className="h-7 w-[50px] mt-5 ms-[-2px]"
                src="/assets/fig.png"
                alt=""
              />
              <img
                src="/assets/Razorpay_logo.svg"
                width={100}
                height={50}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="w-1/2 h-screen flex justify-center  items-center relative">
          <span className="absolute text-sm hover:underline text-green-500 top-10 right-10">
            <Link to="login">Back to login page</Link>
          </span>
          <div className="w-4/5">
            <Box
              component="form"
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "gray",
                  borderWidth: "2px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                },
              }}
            >
              <div className="mb-5 mt-10 mx-auto flex justify-center">
                <TextField
                  sx={{
                    width: "80%",
                  }}
                  id="outlined-basic"
                  label="PhoneNumber  or Email"
                  variant="outlined"
                  onChange={(e) => {
                    setInputs({ ...inputs, username: e.target.value });
                  }}
                  value={inputs.username}
                />
              </div>
            </Box>
            <div className="w-full flex justify-center">
              <button
                className="btn w-3/4 bg-[#0A474C] text-white tracking-wider hover:bg-[#073135] justify-center flex"
                onClick={hadleOtpSend}
                disabled={loading}
              >
                {loading ? (
                  <span className="loading loading-dots"></span>
                ) : (
                  "Send OTP"
                )}
              </button>
            </div>

            {!otpSendSuccess && (
              <div className="text-xs mt-4 text-center text-red-600 font-light">
                To enable the below fields, first you need to click on Send OTP!
              </div>
            )}

            <div className="w-full flex  justify-center mt-4 gap-3">
              <OtpInput
                length={6}
                onOtPSubmit={onOtPSubmit}
                otpSendSuccess={otpSendSuccess}
              />
            </div>
            <div className="w-full flex-col justify-center items-center">
              <Box
                component="form"
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                    borderWidth: "2px",
                    borderRadius: "8px",
                  },
                }}
                autoComplete="on"
              >
                <div className="mb-5 mt-10 mx-auto flex justify-center">
                  <FormControl
                    sx={{
                      width: "80%",
                      display: "flex",
                      justifyItems: "center",
                    }}
                    disabled={!otpSendSuccess}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      new password
                    </InputLabel>
                    <OutlinedInput
                      sx={{
                        width: "100%",
                        color: "black",
                      }}
                      id="outlined-adornment-password "
                      type={showPassword ? "text" : "password"}
                      onChange={(e) =>
                        setInputs({ ...inputs, password: e.target.value })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="confirm password"
                    />
                  </FormControl>
                </div>

                <div className="mb-5 mt-10 mx-auto flex justify-center">
                  <FormControl
                    sx={{
                      width: "80%",
                      display: "flex",
                      justifyItems: "center",
                    }}
                    disabled={!otpSendSuccess}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      confirm password
                    </InputLabel>
                    <OutlinedInput
                      sx={{
                        width: "100%",
                      }}
                      id="outlined-adornment-password "
                      type={showPassword ? "text" : "password"}
                      onChange={(e) =>
                        setInputs({ ...inputs, confirmPass: e.target.value })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="confirm password"
                    />
                  </FormControl>
                </div>
              </Box>
            </div>
            <div className="w-full flex justify-center">
              <button
                className={`btn w-3/4 bg-[#0A474C] text-white tracking-wider hover:bg-[#073135] `}
                disabled={!otpSendSuccess || loading1}
                onClick={hadleChangePassword}
              >
                {loading1 ? (
                  <span className="loading loading-dots"></span>
                ) : (
                  "change password"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OTP;
