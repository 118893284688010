// import React from "react";
// import useConversation from "../../zustand/useConversation";
// import { useSocketContext } from "../../context/SocketContext";

// const Conversation = ({ conversation, lastIdx }) => {
//   const { selectedConversation, setSelectedConversation } = useConversation();

//   const isSelected = selectedConversation?._id === conversation._id;
//   const { onlineUsers } = useSocketContext();
//   const isOnline = onlineUsers.includes(conversation._id);

//   return (
//     <>
//       <div
//         className={`flex gap-2 items-center hover:bg-sky-500 rounded p-2 py-1 cursor-pointer ${
//           isSelected && "bg-sky-500"
//         }`}
//         onClick={() => {
//           setSelectedConversation(conversation);
//         }}
//       >
//         <div className={`avatar ${isOnline ? "online" : ""}`}>
//           <div className="w-12 rounded-full">
//             <img src={conversation.profilePic} alt="user avtar" />
//           </div>
//         </div>

//         <div className="flex flex-col flex-1">
//           <div className="flex gap-3 justify-between">
//             <p className="font-bold text-gray-200">{conversation.fullName}</p>
//             {/* <span className="text-xl">😇</span> */}
//           </div>
//         </div>
//       </div>

//       {!lastIdx && <div className="divider my-0 py-0 h-1" />}
//     </>
//   );
// };

// export default Conversation;

//STARTER CODE FOR CONVERSATION
import React from "react";
import useZusConversation from "../../../zustand/useZusConversation";
import { useSocketContext } from "../../../context/SocketContext";
import { useReadMessage } from "../../../hooks/useGetMessage";

const Conversation = ({ conversation, lastRow }) => {
  const {
    conversations,
    setConversations,
    selectedConversation,
    setSelectedConversation,
  } = useZusConversation();

  // console.log("conversations from:", conversations);
  const { socket } = useSocketContext(); // Access the socket from context

  //to read all messages of selected user
  const { readMessages } = useReadMessage();

  const isSelected = selectedConversation?._id === conversation._id;

  const hadleSeletedConversation = (conversation) => {
    // Reset unseen messages for the selected conversation
    const updatedConversations = conversations.map((item) =>
      item._id === conversation._id ? { ...item, unseenMessages: 0 } : item
    );

    readMessages(conversation._id);
    setConversations(updatedConversations);
    setSelectedConversation(conversation);

    // Emit the joinConversation event to notify the backend
    if (socket && conversation?._id) {
      socket.emit("joinConversation", conversation._id);
      console.log(`Joined conversation: ${conversation._id}`);
    }
  };

  return (
    <>
      <div
        className={`flex gap-2 items-center ${
          isSelected && "bg-[#e9e5e5]"
        } hover:bg-[#e9e5e5] rounded p-2 py-1 cursor-pointer `}
        onClick={() => {
          hadleSeletedConversation(conversation);
        }}
      >
        <div className="online">
          <div className="w-12 h-12 bg-orange-100 flex items-center justify-center text-2xl font-normal text-[#0A474C] rounded-full">
            {conversation.name.charAt(0)}
          </div>
        </div>
        <div className="flex flex-col flex-1 relative">
          <div className="flex-col gap-3 justify-between">
            <p className=" text-black capitalize">{conversation.name}</p>
            <p className=" text-gray-500 text-xs">
              {conversation?.lastMessage}...
            </p>
          </div>
          {conversation.unseenMessages !== 0 && (
            <div className="absolute right-0 top-2 w-7 h-7 rounded-full flex justify-center items-center text-xs font-medium text-white bg-[#0A474C]">
              {conversation.unseenMessages + "+"}
            </div>
          )}
        </div>
      </div>

      {lastRow && <div className="divider my-0 py-0 h-1" />}
    </>
  );
};

export default Conversation;
