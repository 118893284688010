import React from "react";
import FlowTable from "../GlobalCmp/FlowTable";
import QuickGuilde from "../../../../components/QuickGuilde";
import useStoreDetails from "../../../../zustand/sopify/useMetaUserDetails";
import { useGetStoreDetails } from "../../../../hooks/integrationHooks/useShopify";

const ConfirmationFlow = () => {
  // useGetStoreDetails()
  const { storeLoading, storeDetails } = useStoreDetails();

  const quickReplyObj = {
    width: "full",
    text1: "Easily configure Ecomm+ to automate your shopify messages.",
    text2:
      "Automatic ConfirmationFlow Cart Reminders, Order Notifications, COD recovery on whatsapp.",
    // link1: "How to get Access Token and API Secret Key",
    // link2: "How to setup automated shopify messages",
  };

  const flowtabdata = [
    {
      flowName: "COD Order Confirmation",
      campaign: "N/A",
      status: storeDetails?.shopData?.codOrderConfirmationSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.codOrderConfirmationSettings?.isFlowRun ||
        false,
      link: "codOrderConfirmationSettings",
    },
    {
      flowName: "COD Order Follow Up",
      campaign: "OrderFlow Cart 1: 19 Oct 12:23:38",
      status: storeDetails?.shopData?.codOrderFollowupSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.codOrderFollowupSettings?.isFlowRun || false,
      link: "codOrderFollowupSettings",
    },
    {
      flowName: "COD Order Confirm Status",
      campaign: "N/A",
      status: storeDetails?.shopData?.codOrderConfirmStatusSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.codOrderConfirmStatusSettings?.isFlowRun ||
        false,
      link: "codOrderConfirmStatusSettings",
    },
    {
      flowName: "COD Order Cancel Status",
      campaign: "N/A",
      status: storeDetails?.shopData?.codOrderCancelStatusSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.codOrderCancelStatusSettings?.isFlowRun ||
        false,
      link: "codOrderCancelStatusSettings",
    },
  ];
  return (
    <div className="w-full min-h-[90vh] ">
      <QuickGuilde data={quickReplyObj} />
      {!storeLoading && <FlowTable data={flowtabdata} />}
    </div>
  );
};

export default ConfirmationFlow;
