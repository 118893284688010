import React, { useEffect, useRef, useState } from "react";

const OtpInput = ({ length = 6, onOtPSubmit = () => {}, otpSendSuccess }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRef = useRef([]);
  // const inputRefs = Array(length).fill(null).map(() => useRef(null));
  useEffect(() => {
    if (inputRef.current[0]) {
      inputRef.current[0].focus();
    }
  }, []);

  const handleOtpchange = (index, e) => {
    const value = e.target.value;
    // if (NaN(value))  return

    const newotp = [...otp];
    //allow only one input
    newotp[index] = value.substring(value.length - 1);
    setOtp(newotp);

    //submit trigger

    //to combine otp  and send to backend
    const combinedOTP = newotp.join("");
    if (combinedOTP.length === length) {
      onOtPSubmit(combinedOTP);
    }

    //move to next filed if current one is filled
    if (value && index < length - 1 && inputRef.current[index + 1]) {
      inputRef.current[index + 1].focus();
    }
  };
  const HandleOnclick = (index) => {
    inputRef.current[index].setSelectionRange(1, 1);
  };
  //On backspace it should to previous filed
  const HandlekeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRef.current[index - 1]
    ) {
      inputRef.current[index - 1].focus();
    }
  };

  // console.log(inputRef);
  return (
    <>
      {" "}
      {otp.map((value, index) => {
        return (
          <input
            key={index}
            type="text"
            value={value}
            disabled={!otpSendSuccess}
            ref={(input) => (inputRef.current[index] = input)}
            onChange={(e) => handleOtpchange(index, e)}
            onClick={() => HandleOnclick(index)}
            onKeyDown={(e) => HandlekeyDown(index, e)}
            className={`w-12 h-12 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 text-center font-bold ${
              otpSendSuccess ? "bg-white" : "disabled"
            } text-black`}
          />
        );
      })}{" "}
    </>
  );
};

export default OtpInput;
