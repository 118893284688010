import React from "react";
import { TextComponent } from "../CreateTemplate";

function TamplateFormat({
  inputValue,
  setInputValue,
  objectsArray,
  setObjectsArray,
  setbubble,
  inputBubble,
  selectedType,
  cards,
  setCards
}) {
  const handleChange = (e, state) => {
    const value = e.target.value;
    const regex = /{{(\d+)}}/g;
    let match;
    const idsFound = new Set();

    // Extract existing IDs
    while ((match = regex.exec(value)) !== null) {
      idsFound.add(parseInt(match[1], 10));
    }

    // Sort the IDs and create new sequential IDs
    const sortedIds = Array.from(idsFound).sort((a, b) => a - b);
    const idMapping = new Map();
    let currentId = 1;
    sortedIds.forEach((originalId) => {
      idMapping.set(originalId, currentId++);
    });

    // Replace the original IDs with the new sequential IDs
    let modifiedValue = value.replace(
      regex,
      (match, p1) => `{{${idMapping.get(parseInt(p1, 10))}}}`
    );

    // Create a lookup map from objectsArray
    const lookupMap = new Map(
      objectsArray.map((item) => [item.id, item.parameterVal])
    );

    // Update the new objects array with sequential IDs
    const newObjectsArray = Array.from(idMapping.values()).map((id) => ({
      id,
      parameterVal: lookupMap.get(id) || "", // Use existing value if it exists, otherwise empty string
    }));

    state(modifiedValue);
    setObjectsArray(newObjectsArray);
  };


  console.log("input bubble looks likes  123:", inputBubble);
  console.log("input bubble looks likes  123:", objectsArray);

  return (
    <>
    

      {selectedType === "CAROUSEL" ? (
        <div>
          <TextComponent
            heading="Template Format /bubble message "
            body="Use text formatting - *bold* , _italic_ & ~strikethrough~
           Your message content. Upto 1024 characters are allowed."
            body1="e.g. - Hello {{1}}, your code will expire in {{2}} mins."
          />
          <textarea
            type="text"
            value={inputBubble}
            onChange={(e) => handleChange(e, setbubble)}
            placeholder="Enter your message here..."
            className="input input-bordered w-full h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
          />
          {/* <TextComponent
            heading="Card 1 Body"
            body="Your carousel template type should fall under one of these categories"
          /> */}
          {/* <input
            type="text"
           
            onChange={(e) => {
              handleChange(e, setCards)
            }}
            placeholder="Enter card body  "
            className="input input-bordered w-full h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
          /> */}
        </div>
      ) : (
        <div>
        <TextComponent
          heading="Template Format"
          body="Use text formatting - *bold* , _italic_ & ~strikethrough~
            Your message content. Upto 1024 characters are allowed."
          body1="e.g. - Hello {{1}}, your code will expire in {{2}} mins."
        />
        <textarea
          type="text"
          value={inputValue}
          onChange={(e) => handleChange(e, setInputValue)}
          placeholder="Enter your message here..."
          className="input input-bordered w-full h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
        />
      </div>
      )}
    </>
  );
}

export default TamplateFormat;
