import React, { useState } from "react";

import TabPage from "../../components/Biling/TabPage";

function Biling() {
  const [isTabActive, setTabactive] = useState("tab1");

  const HandleActiveTab = (val) => {
    setTabactive(val);
  };

  return (
    <>
      <div className="flex justify-between items-center bg-white px-5 py-3 shadow sticky top-0 z-50 text-2xl">
        Biling and Usage
      </div>
      <div className="h-full  mt-5 ">
        <div className="w-3/4 mx-auto  bg-white rounded-sm mt-4  h-24 p-2">
          <p className="text-sm text-gray-500 ml-3 mt-4">Current Plan</p>
          <h1 className="text-xl ml-3">
            Basic <span className="text-sm text-gray-500">(monthly)</span>
          </h1>
          <h3 className="text-sm text-gray-500 text-end mr-4">
            renews on 28/05/24
          </h3>
        </div>
        <div className="w-3/4 mx-auto rounded-sm mt-4 h-52 flex justify-between">
          <div className="w-1/2 shadow-md bg-white rounded-md p-3 ">
            <h3 className="text-xs p-1  ">
              Free Service Conversation
              <span className="text-lg text-end p-1 ml-32">0 /1000 used</span>
            </h3>
            <progress
              className="progress  progress-success  my-3 w-3/4"
              value="10"
              max="100"
            ></progress>
            <h3 className="text-lg">WhatsApp Conversation Credits (WCC)</h3>
            <p className="text-xl">₹666</p>
          </div>
          <div className="w-2/5 bg-white shadow-md rounded-md p-3  ">
            <h3 className="text-lg p-1">Payment Method</h3>
            <p className="text-xs text-red-500">
              Add billing address to enable payment method
            </p>
            <p className="text-xs text-red-500 ">
              Add your credit card for future purchases.
            </p>
          </div>
          {/* <div className="w-1/2  ">
          <p className="text-xl ">Billing Address</p>
          <p className="text-lg">
            4TH FLOOR, E- 446, SUMEL -11 INDIAN TEXTILE PLAZA SUB PLOT NO. 1/1
            TP SNO.14, NR. NAMASTE CIRCLE, SHAHIBAUG, Ahmedabad, 380004,
            Gujarat, IN
          </p>
          </div> */}
        </div>
        <div className="w-3/4 mx-auto rounded-sm mt-4 ">
          <div className="w-1/2 bg-white rounded-md shadow-md h-auto p-3 ">
            <p className="text-lg ">Billing Address</p>
            <p className="text-xs p-2">
              4TH Floor, E- 446, SUMEL -11 INDIAN TEXTILE PLAZA SUB PLOT NO. 1/1
              TP SNO.14, NR. NAMASTE CIRCLE, SHAHIBAUG, Ahmedabad, 380004,
              Gujarat, IN
            </p>
          </div>
        </div>
        <div className="w-3/4 mx-auto bg-white rounded-md mt-7 shadow-md  p-3">
          <h3 className="text-xl text-[#1F474C] mt-9 ">MAU Usage</h3>
          <div
            role="tablist"
            className="tabs tabs-boxed mt-7 flex w-1/2 justify-between   bg-[#F7F7F7] text-black
            \ text-center border-1"
          >
            <div
              role="tab"
              className={`tab w-1/2  transition duration-700 ease-out ${
                isTabActive === "tab1"
                  ? "tab-border  bg-[#1F474C] text-white    w-1/2 text-center "
                  : " tab-border bg-white-500 text-black"
              }`}
              onClick={() => {
                HandleActiveTab("tab1");
              }}
            >
              <span
                className={` ${
                  isTabActive === "tab1" ? "text-white" : "text-black"
                }`}
              >
                {" "}
                Billing Period Wise
              </span>
            </div>
            <div
              role="tab"
              className={`tab w-1/2  transition duration-0  ${
                isTabActive === "tab2"
                  ? "tab-border   bg-[#1F474C] text-white   w-1/2 text-center  "
                  : "tab-border   bg-white-500 text-black"
              }`}
              onClick={() => {
                HandleActiveTab("tab2");
              }}
            >
              <span
                className={` ${
                  isTabActive === "tab2" ? "text-white" : "text-black"
                }`}
              >
                {" "}
                All
              </span>
            </div>
          </div>
          {/* <TabPage /> */}
          <div>
            {isTabActive === "tab1" && <TabPage />}
            {isTabActive === "tab2" && <TabPage />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Biling;
