// import React from "react";
// import Conversation from "./Conversation";
// import useGetConversations from "../../hooks/useGetConversations";

// const Conversations = () => {
//   const { loading, conversations } = useGetConversations();

//   console.log("Conversations :", conversations);

//   return (
//     <div className="py-2 flex flex-col overflow-auto">
//       {conversations.map((conversation, idx) => (
//         <Conversation
//           key={conversation._id}
//           conversation={conversation}
//           lastIdx={idx === conversations.length - 1}
//         />
//       ))}

//       {loading ? <span className="loading loading-spinner"></span> : null}
//     </div>
//   );
// };

// export default Conversations;

// STARTER CODE FOR CONVERSATION
import React, { useMemo } from "react";
import Conversation from "./Conversation";
import useGetConversations from "../../../hooks/useGetConversations";
import useNewUnseenMessages from "../../../hooks/useNewUnseenMessages";
import useZusConversation from "../../../zustand/useZusConversation";
import { UseAuthContext } from "../../../context/AuthContext";

const Conversations = ({ searchQuery, agentQuery }) => {
  // const { loadingCNV, conversations } = useGetConversations();
  const { loadingCNV,  conversations, setConversations } =
    useZusConversation();
  const { authUser } = UseAuthContext();

  // useNewUnseenMessages();

  const currentChatIntervedUser = authUser?.agentId
    ? authUser.agentId
    : authUser._id;

  // console.log("conversations:", conversations);

  // const filteredConversations = conversations?.filter((conversation) => {
  //   return conversation.name.toLowerCase().includes(searchQuery.toLowerCase());
  // });

  // Apply filtering and sorting whenever conversations change
  const filteredConversations = useMemo(() => {
    const sortedConversations = [...(conversations || [])].sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt) // Sort by updatedAt (descending)
    );

    if (searchQuery) {
      return sortedConversations.filter((conversation) =>
        conversation.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    console.log("agentQuery:", agentQuery.heading);

    if (agentQuery.heading === "requesting") {
      return sortedConversations.filter(
        (conversation) => conversation?.requesting === true
      );

      // !selectedConversation?.requesting;
      // selectedConversation?.agentId;
    } else if (agentQuery.heading === "active") {
      return sortedConversations.filter((conversation) => {
        const now = Date.now(); // Current timestamp in milliseconds
        const expiresAt = new Date(conversation?.expiresAt).getTime(); // Convert ISO string to timestamp in milliseconds
        const isChatExpired = now > expiresAt; // Determine if chat is expired
        return !isChatExpired; // Only include conversations that are not expired
      });
    } else if (agentQuery.heading === "expired") {
      return sortedConversations.filter((conversation) => {
        const now = Date.now(); // Current timestamp in milliseconds
        const expiresAt = new Date(conversation?.expiresAt).getTime(); // Convert ISO string to timestamp in milliseconds
        const isChatExpired = now > expiresAt; // Determine if chat is expired
        return isChatExpired; // Only include conversations that are not expired
      });
    } else if (agentQuery.heading === "intervened") {
      console.log("agentQuery:", agentQuery.heading);
      console.log("agentQuery subHeading:", agentQuery.subHeading);
      console.log("test2", currentChatIntervedUser, "/", agentQuery.subHeading);

      if (currentChatIntervedUser === agentQuery.subHeading) {
        console.log(
          "tillu:",
          currentChatIntervedUser === agentQuery.subHeading
        );

        return sortedConversations.filter(
          (conversation) =>
            conversation?.agentId &&
            conversation?.agentId === agentQuery.subHeading
        );
      } else if (agentQuery.subHeading === "any") {
        return sortedConversations.filter(
          (conversation) =>
            conversation?.agentId && conversation?.agentId !== null
        );
      } else {
        return sortedConversations.filter(
          (conversation) =>
            conversation?.agentId &&
            conversation?.agentId === agentQuery.subHeading
        );
      }
    }

    return sortedConversations; // Return sorted if no search query
  }, [conversations, searchQuery, agentQuery]); // Recalculate when conversations or searchQuery changes

  return (
    <div className="py-2 mx-4 mt-2 flex flex-col overflow-auto">
      {conversations.length === 0 && (
        <div className="h-[100vh] w-full flex justify-center items-center opacity-55 text-xl">
          <div>No Conversations!</div>
        </div>
      )}

      {!filteredConversations &&
        conversations &&
        conversations.map((conversation, idx) => (
          <Conversation
            key={conversation._id}
            conversation={conversation}
            lastIdx={idx === conversations.length - 1}
          />
        ))}

      {filteredConversations &&
        filteredConversations.map((conversation, idx) => (
          <Conversation
            key={conversation._id}
            conversation={conversation}
            lastIdx={idx === filteredConversations.length - 1}
          />
        ))}

      {loadingCNV && <span className="loading loading-dots"></span>}
    </div>
  );
};

export default Conversations;
