import { MdDelete } from "react-icons/md";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSetOptinSetting } from "../../hooks/useOptin";
import toast from "react-hot-toast";

function OptSystem() {
  const { loading, setOptinSetting } = useSetOptinSetting();
  const [isEnabled, setIsEnabled] = useState(false);
  const [isEnnableOptOut, setIsEnabledoptOut] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [response, setResponse] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [isradio, setRadio] = useState(false);
  const [keysOptIn, setKeyOptIn] = useState([]);
  const [isEditingforOpt_in, setIsEditingOpt_out] = useState(false);
  const [messageOpt_In, setMessageopt_out] = useState("");
  const optOutRef = useRef(null);
  const optInRef = useRef(null);

  // Fetch settings from the server
  useEffect(() => {
    axios
      .get("/api/optin/setting")
      .then((response) => {
        const settings = response.data;
        console.log(settings);

        setIsEnabled(settings.apiCampaignOptOut);
        setIsEnabledoptOut(settings.optOutResponseEnabled);
        setKeywords(settings.optOutKeywords);
        setMessage(settings.optOutResponseMessage);
        setRadio(settings.optInResponseEnabled);
        setKeyOptIn(settings.optInKeywords);
        setMessageopt_out(settings.optInResponseMessage);
        adjustTextareaRows(optOutRef.current);
        adjustTextareaRows(optInRef.current);
      })
      .catch((error) => {
        console.error("There was an error fetching the settings!", error);
        toast.error(error?.response?.data?.error || error.message);
      });
  }, []);
    



  

  const handleToggle = () => {
    setIsEnabled(!isEnabled);
  };

  const handleToggle1 = () => setIsEnabledoptOut(!isEnnableOptOut);
  const deleteKeywordOptout = (index) => {
    const newKeywords = keywords.filter((_, i) => i !== index);
    setKeywords(newKeywords);
  };

  const handleKeywordChange = (index, value) => {
    const newKeywords = [...keywords];
    newKeywords[index] = value;
    setKeywords(newKeywords);
  };

  const addKeyword = () => setKeywords([...keywords, ""]);
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    setResponse(message);
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
    adjustTextareaRows(e.target);
  };

  const handleToggleforOptin = () => setRadio(!isradio);
  const handledeletoptin = (index) => {
    const newKeywords = keysOptIn.filter((_, i) => i !== index);
    setKeyOptIn(newKeywords);
  };

  const handleKeywordforOptIn = (index, value) => {
    const newKeywords = [...keysOptIn];
    newKeywords[index] = value;
    setKeyOptIn(newKeywords);
  };

  const addKeywordforOptIn = () => setKeyOptIn([...keysOptIn, ""]);
  const handleEditopt_in = () => {
    setIsEditingOpt_out(true);
  };

  const handleSaveopt_In = () => {
    setIsEditingOpt_out(false);
    setResponse(messageOpt_In);
  };

  const handleChangeOpt_in = (e) => {
    setMessageopt_out(e.target.value);
    adjustTextareaRows(e.target);
  };

  const adjustTextareaRows = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight + 5}px`;
    }
  };

  const handleSaveSettings = async () => {
    const settings = {
      apiCampaignOptOut: isEnabled,
      optOutKeywords: keywords,
      optOutResponseEnabled: isEnnableOptOut,
      optOutResponseMessage: message,
      optInKeywords: keysOptIn,
      optInResponseEnabled: isradio,
      optInResponseMessage: messageOpt_In,
    };

    console.log(settings);

    await setOptinSetting(settings);
  };

  return (
    <>
      <div className="mx-auto w-11/12 ">
        <div className="bg-white rounded-lg shadow-md p-6 mt-16">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-lg font-semibold text-gray-800">
                API Campaign Opt-out
              </h2>
              <p className="text-sm text-gray-600 mt-1">
                Enable this if you don't wish to send api campaign to opted-out
                contacts
              </p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer bg-white"
                checked={isEnabled}
                onChange={handleToggle}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>

        <div className="mx-auto bg-white rounded-lg shadow-md p-6 mt-9">
          <div className="flex justify-between items-start mb-6">
            <div></div>
            <div className="flex items-center space-x-2">
              <span className="text-sm font-medium text-gray-700">
                Opt-out Response
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only bg-white peer"
                  checked={isEnnableOptOut}
                  onChange={handleToggle1}
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>

          <div className="flex space-x-6">
            <div className="w-1/2">
              <h3 className="text-lg font-semibold mb-3">Opt-out Keywords</h3>
              <p className="text-sm text-gray-600 mt-1">
                The user will have to type exactly one of these messages on
                which they should be automatically opted-out
              </p>
              <div>
                {keywords.map((keyword, index) => (
                  <div key={index} className="flex mb-2">
                    <input
                      type="text"
                      value={keyword}
                      onChange={(e) =>
                        handleKeywordChange(index, e.target.value)
                      }
                      className="w-full px-3 py-2 mb-2 border border-gray-300 rounded-md mt-3 bg-white text-black"
                      placeholder="Enter Keywords"
                    />
                    <button>
                      <MdDelete
                        className="items-end ms-2 text-2xl text-gray-500 hover:cursor-pointer"
                        onClick={() => deleteKeywordOptout(index)}
                      />
                    </button>
                  </div>
                ))}
              </div>
              <button
                onClick={addKeyword}
                className="text-blue-600 hover:underline mt-2"
              >
                + Add more
              </button>
            </div>

            <div className="w-1/2">
              <h3 className="text-lg font-semibold mb-3">Opt-out Response</h3>
              <div className="flex justify-between items-center mt-2">
                <textarea
                  ref={optOutRef}
                  type="text"
                  value={message}
                  onChange={handleChange}
                  disabled={!isEditing}
                  placeholder="Write a message..."
                  className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-black me-2"
                />
                {isEditing ? (
                  <button
                    onClick={handleSave}
                    className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    save
                  </button>
                ) : (
                  <button
                    onClick={handleEdit}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    edit
                  </button>
                )}
              </div>
            </div>
          </div>

          <button
            disabled={loading}
            className="mt-6 px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700"
            onClick={handleSaveSettings}
          >
            {loading ? (
              <span className="loading loading-dots"></span>
            ) : (
              "Save Settings"
            )}
          </button>
        </div>

        <div className="mx-auto bg-white rounded-lg shadow-md p-6 mt-9">
          <div className="flex justify-between items-start mb-6">
            <div>
              <h2 className="text-xl font-semibold text-gray-800">
                Opt-in Keywords
              </h2>
              <p className="text-sm text-gray-600 mt-1">
                The user will have to type exactly one of these messages on
                which they should be automatically opted-out
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <span className="text-sm font-medium text-gray-700">
                Opt-in Response
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isradio}
                  onChange={handleToggleforOptin}
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              <button className="px-3 py-1 text-sm text-blue-600 border border-blue-600 rounded hover:bg-blue-50">
                Configure
              </button>
            </div>
          </div>

          <div className="flex space-x-6">
            <div className="w-1/2">
              <h3 className="text-lg font-semibold mb-3">Opt-in Keywords</h3>
              {keysOptIn.map((keyword, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    value={keyword}
                    onChange={(e) =>
                      handleKeywordforOptIn(index, e.target.value)
                    }
                    className="w-full px-3 py-2 mb-2 border border-gray-300 rounded-md mt-3 bg-white text-black"
                    placeholder="Enter Keywords"
                  />
                  <button>
                    <MdDelete
                      className="items-end ms-2 text-2xl text-gray-500 hover:cursor-pointer"
                      onClick={() => handledeletoptin(index)}
                    />
                  </button>
                </div>
              ))}
              <button
                onClick={addKeywordforOptIn}
                className="text-blue-600 hover:underline mt-2"
              >
                + Add more
              </button>
            </div>

            <div className="w-1/2">
              <h3 className="text-lg font-semibold mb-3">Opt-in Response</h3>
              <div className="flex justify-between items-center mt-2">
                <textarea
                  ref={optInRef}
                  type="text"
                  value={messageOpt_In}
                  onChange={handleChangeOpt_in}
                  disabled={!isEditingforOpt_in}
                  placeholder="Write a message..."
                  className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-black me-2"
                />
                {isEditingforOpt_in ? (
                  <button
                    onClick={handleSaveopt_In}
                    className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    save
                  </button>
                ) : (
                  <button
                    onClick={handleEditopt_in}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    edit
                  </button>
                )}
              </div>
            </div>
          </div>

          <button
            disabled={loading}
            className="mt-6 px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700"
            onClick={handleSaveSettings}
          >
            {loading ? (
              <span className="loading loading-dots"></span>
            ) : (
              "Save Settings"
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default OptSystem;
