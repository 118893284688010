import React, { useEffect, useState } from "react";
import { PiWarningCircleLight } from "react-icons/pi";
import { IoImageSharp, IoDocumentText, IoPlayCircle } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaArrowRightLong, FaArrowUpRightFromSquare } from "react-icons/fa6";
import { MdOutlineCall } from "react-icons/md";
import { HiLink } from "react-icons/hi2";
import { tokens } from "../../theme";

import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Sent from "../../pages/campaigns/Sent";
import Broadcast from "../../pages/contacts/Broadcast";
import { FaArrowLeft } from "react-icons/fa";
import { BiVideo } from "react-icons/bi";
import { IoEllipsisVertical } from "react-icons/io5";
import { BiLinkExternal } from "react-icons/bi";
import { CgMailReply } from "react-icons/cg";
import { LuForward } from "react-icons/lu";
import { CgSmileMouthOpen } from "react-icons/cg";
import { MdOutlineCameraAlt } from "react-icons/md";
import { IoMdMic } from "react-icons/io";
import { BsPaperclip } from "react-icons/bs";
function StatusBox({ children }) {
  return <div className="h-32 w-[45%] shadow-lg p-4">{children}</div>;
}

function BoxHeading({ data }) {
  return (
    <div>
      <h2 className="text-sm flex items-center gap-2 text-gray-500">
        {data.title}
        <span>
          <PiWarningCircleLight className="text-sm text-gray-400" />
        </span>
      </h2>
      <p className="text-lg font-bold text-black">{data.count}</p>
    </div>
  );
}

function Campaign({
  firstdiv,
  secoundiv,
  lastDiv,
  fullData,
  template,
  tableData,

}) {
  // console.log("table data looks likes this :", tableData);
  const [showtemplate, setTemplate] = useState([]);

  useEffect(() => {
    setTemplate(template);
  }, [template]);

  //list for all data
  const [isheader, setheader] = useState("");
  const [isbody, setbody] = useState("");
  const [isfooter, setfooter] = useState("");
  const [buttons, setbuttons] = useState([]);
  const [istitle, setTitle] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedmedia, setselectedmedia] = useState(null);
  const [istemplateid, settemplateid] = useState("");
  useEffect(() => {
    if (showtemplate?.components) {
      const components = showtemplate.components;
      let templateFormat = "";
      let headerText = "";
      let bodyText = "";
      let footer = "";
      let totalbutton = [];

      for (let j = 0; j < components.length; j++) {
        const component = components[j];

        console.log("Component looks like this:", component);

        if (component.type === "HEADER" && component.format) {
          templateFormat = component.format;

          if (component.format === "Text") {
            headerText = component.text;
            console.log("Header looks like this in map function:", headerText);
          }
        } else if (component.type === "BODY" && component.text) {
          bodyText = component.text;
        } else if (component.type === "FOOTER" && component.text) {
          footer = component.text;
        } else if (component.type === "BUTTONS" && component.buttons) {
          totalbutton = totalbutton.concat(component.buttons);
        }
      }

      // Update the state with the extracted values
      setheader(templateFormat);
      setbody(bodyText);
      setfooter(footer);
      setbuttons(totalbutton);
      setTitle(headerText); // Assuming Title is set from headerText
    }
  }, [showtemplate]);

  let content = null;

  if (isheader?.toUpperCase() === "IMAGE") {
    content = <IoImageSharp className="h-40 w-52 bg-orange-300 rounded-md" />;
  } else if (isheader?.toUpperCase() === "VIDEO") {
    content = (
      <div className="text-4xl text-blue-600 h-40 w-52 rounded-md">
        <IoPlayCircle className="h-40 w-52" />
      </div>
    );
  } else if (isheader?.toUpperCase() === "DOCUMENT") {
    content = <IoDocumentText className="h-40 w-52 text-red-400 " />;
  } else if (isheader?.toUpperCase() === "LOCATION") {
    content = (
        <div className="text-4xl text-red-600 h-40 w-52 rounded-md flex items-center justify-center">
        <FaLocationDot className=" text-2xl h-24 w-52 text-red-600" />
      </div>
    );
  } else if (isheader?.toUpperCase() === "TEXT") {
    content = (
      <div className=" w-full bg-white">
        <p className="font-whatsapp-bold font-bold ml-2">
          {istitle.toLowerCase()}
        </p>
      </div>
    );
  }

  //code for table data
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedContact, setselecetedContact] = useState([]);
  const [selectedlist, setselectedlist] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "Id", width: 100 },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 200,
    },
    { field: "phone", headerName: "Phone Number", width: 200 },
    { field: "createdAt", headerName: "Created At", width: 200 },

    { field: "reason", headerName: "Failed reason", width: 400 },
  ];

  const [mockDataContacts, setMockDataContacts] = useState([]);
 console.log(" tables  looks likes :",tableData);
  useEffect(() => {
    if (Array.isArray(tableData) && tableData.length > 0) {
      // Check if tableData is an array and has at least one item

      // Case where there is failed data
      if (tableData[0].contacts) {
        setMockDataContacts(
          tableData.map((contact, index) => ({
            id: index + 1,
            phone: contact.contacts?.mobile_Number?.trim() || "n/A", // Handle phone number from nested contacts
            reason: contact.reason, // Add the reason field if available
            ...contact.contacts, // Spread properties from the nested contacts field
          }))
        );
      } else {
        // If tableData is an array of contacts (no nested contacts property)
        setMockDataContacts(
          tableData.map((contact, index) => ({
            id: index + 1,
            phone: contact.mobile_Number?   contact.mobile_Number.trim()  : "N/A",//Same phone handling as above
            ...contact, // Spread the remaining contact properties
          }))
   
        );
       
      }
    }
  }, [tableData]);
  console.log("data in states in looks likes :",mockDataContacts)

  const handleSelectionChange = (newSelection) => {
    if (tableData && tableData.length > 0) {
      setSelectionModel(newSelection);
      const selectedRows = newSelection.map((id) =>
        mockDataContacts.find((row) => row.id === id)
      );

      console.log("Selected rows look like this:", selectedRows);
      const mobileNumbers = selectedRows.map((entry) => entry.mobile_Number);
      const selectedid = selectedRows.map((select) => select._id);
      console.log("selected mobile number looks likes this :", mobileNumbers);
      setselecetedContact(mobileNumbers);
      setselectedlist(selectedid);
    }
  };

  const formatText = (text) => {
    return text
      .replace(/\*(.*?)\*/g, "<b>$1</b>") // Bold
      .replace(/_(.*?)_/g, "<i>$1</i>") // Italic
      .replace(/~(.*?)~/g, "<s>$1</s>"); // Strikethrough
  };
  return (
    <>
      <div className="w-full flex gap-3">
       

        <div className="w-[380px] shadow-lg">
          <div className="container  flex justify-center mt-3">
            <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
              <div className="camera "></div>
              <div className="display bg-white min-h-[450px] relative ">
                <div className="flex flex-col min-h-[500px]  bg-gray-100">
                  <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                    <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                    <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                      <span className="text-black font-bold">T</span>
                    </div>
                    <div className="flex-grow">
                      <div className="font-semibold mt-3">TheBotmode</div>
                      <div className="text-xs  font-normal ">online</div>
                    </div>
                    <div className="flex items-center h-[45px] mt-3 ">
                      <BiVideo className="mx-2 text-lg text-white" />
                      <MdOutlineCall className="mx-1 text-lg text-white" />
                      <IoEllipsisVertical className="mx-1 text-lg text-white" />
                    </div>
                  </div>

                  {/* Chat area */}
                  <div className="flex-grow bg-gray-200 p-4 relative">
                    <img
                      src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
                      className="h-full w-full object-cover absolute inset-0"
                      alt="whatsapp background"
                    />
                    <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55">
                      <span className="text-xs text-center  font-normal  text-[#54656F]">
                        Today
                      </span>
                    </div>
             
                    <div className="absolute rounded-t-md top-15 left-3 w-10/12 mx-auto max-h-[calc(100%-96px)] overflow-y-auto custom-scroll ">
                      <div className="bg-white shadow p-2 text-center overflow-hidden">
                        {/* {selectedType !== "None" && selectedType !== "Text" && ( */}
                        <div className="w-auto  bg-[#F8F8F8] rounded-md flex gap-5  justify-center items-center ">
                          <figure className=" ">{content}</figure>
                        </div>
                        {/* )} */}
                        <div className="text-sm  font-whatsapp-bold text-start mt-3">
                          {istitle}
                        </div>
                        <div className="font-normal text-sm mt-3 text-start overflow-hidden">
                          <div
                            className="overflow-hidden  "
                            dangerouslySetInnerHTML={{
                              __html: formatText(isbody),
                            }}
                          />
                        </div>
                        <div className="text-xs font-normal text-start text-blue-600 mt-4">
                          {isfooter}
                        </div>
                      </div>

                      {buttons.map((button, index) => (
                        <div key={index} className="">
                          {button.type === "PHONE_NUMBER" && (
                            <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                              <MdOutlineCall className="text-blue-600 text-sm" />
                              {button.text}
                            </div>
                          )}
                          {button.type === "url" && (
                            <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                              <BiLinkExternal className="text-blue-600 text-sm" />{" "}
                              {button.text}
                            </div>
                          )}
                          {button.type === "QUICK_REPLY" && (
                            <div className="templatebuttons rounded-b-md flex gap-1 items-center justify-center border-blue border-1">
                              <CgMailReply className="text-blue-600 text-sm" />
                              {button.text}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="h-6 w-6 rounded-full bg-gray-500 bg-opacity-30 absolute top-[35%] ml-1 right-1.5 flex items-center justify-center">
                      <LuForward className="text-white text-sm" />
                    </div>
                  </div>

                  {/* Input area */}
                  <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">
                    <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                      <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                      <input
                        type="text"
                        placeholder="Type a message"
                        className="w-[60%] outline-none text-gray-600 bg-white"
                        readOnly
                      />
                      {/* <Paperclip className="text-gray-600 mx-2" /> */}
                      <BsPaperclip className="text-gray-600  mx-2 text-lg" />

                      {/* <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"> */}
                      {/* <img src="/api/placeholder/32/32" alt="Camera icon" className="w-5 h-5" /> */}
                      <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                      {/* </div> */}
                    </div>
                    <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                      {/* <Mic className="text-white" /> */}
                      <IoMdMic className="text-white text-lg " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[65%] h-screen shadow-lg">
          <div className="flex mt-6 gap-3">
            <StatusBox>
              <BoxHeading data={firstdiv} />
            </StatusBox>
            <StatusBox>
              <BoxHeading data={secoundiv} />
            </StatusBox>
            <StatusBox>
              <BoxHeading data={lastDiv} />
            </StatusBox>
          </div>
          <div className="mx-auto h-40 shadow-lg">
            <div className="flex h-auto">
              {fullData.map((data, index) => (
                <div key={index} className=" h-28 w-[25%]  p-4">
                  <BoxHeading data={data} />
                </div>
              ))}
            </div>
          </div>
          <p className="text-sm text-gray-500  mt-5 ml-2">Campaign Messages </p>
        </div>
        <div className="mx-auto"></div>
      </div>
      <div>
        {tableData && tableData.length > 0 ? (
          <Box
            m="8px 0 0 0"
            width="83.33%"
            marginX="auto"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
              },
              "& .css-wc9pnx-MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#0A474C",
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller:nth-of-type(odd)": {
                backgroundColor: "white",
              },
              "& .MuiCheckbox-root": {
                color: `#2cb59a !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `#0A474C !important`,
              },
              "& .PrivateSwitchBase-input": {
                color: `#0A474C !important`,
              },
              "& .css-1fe4m6k .MuiCheckbox-root": {
                color: `red !important`,
              },
              "& .css-wc9pnx-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
                {
                  outline: `none`,
                },
              "& .css-204u17-MuiDataGrid-main": {
                width: "100% !important",
                marginX: "auto !important",
              },
              "& .css-1j9kmqg-MuiDataGrid-toolbarContainer": {
                width: "100% !important",
                marginX: "auto !important",
                marginBottom: "10px !important",
              },
              "& .MuiDataGrid-footerContainer": {
                position: "fixed !important",
                bottom: "0",
                left: "0",
                gap: "132px",
                width: "100% !important",
                overflow: "hidden",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            <div className="mx-auto flex justify-end mr-40 mt-14">
              {selectionModel?.length > 0 ? (
                <Broadcast
                  mobileNumbers={selectedContact}
                  selectedList={selectedlist}
                />
              ) : (
                <div
                  className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
                  disabled
                >
                  Broadcast
                </div>
              )}
            </div>
            <DataGrid
              rows={mockDataContacts}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              onSelectionModelChange={handleSelectionChange}
              selectionModel={selectionModel}
            />
          </Box>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}

export default Campaign;
