export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7312154549",
    status: "Inactive",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7331422889",
    status: "Inactive",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7398267399",
    status: "Inactive",
  },
  {
    id: 4,
    name: "Anya Stark",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7342567429",
    status: "Inactive",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7344511899",
    status: "Inactive",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7354564839",
    status: "Inactive",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7312401239",
    status: "Inactive",
  },
  {
    id: 8,
    name: "Rossini Frances",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7344455559",
    status: "Inactive",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 10,
    name: "Enteri Redack",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7344455559",
    status: "Inactive",
  },
  {
    id: 11,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 12,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 13,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 14,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 15,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 16,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 17,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 18,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 19,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 20,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 21,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 22,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 23,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 24,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 25,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 26,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
  {
    id: 27,
    name: "Steve Goodman",
    source: "IMPORTED",
    createdAt: "06:09, 27 Apr 2024",
    incoming: "Allowed",
    phone: "+91 7355562399",
    status: "Inactive",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockLineData = [
  {
    id: "User Messages",
    color: "hsl(225, 70%, 50%)",
    data: [
      {
        x: "01 May",
        y: 0,
      },
      {
        x: "02 May",
        y: 50,
      },
      {
        x: "03 May",
        y: 100,
      },
      {
        x: "04 May",
        y: 120,
      },
      {
        x: "05 May",
        y: 150,
      },
      {
        x: "06 May",
        y: 200,
      },
      {
        x: "07 May",
        y: 130,
      },
      {
        x: "08 May",
        y: 190,
      },
      {
        x: "09 May",
        y: 150,
      },
      {
        x: "10 May",
        y: 220,
      },
      {
        x: "11 May",
        y: 100,
      },
      {
        x: "12 May",
        y: 180,
      },
    ],
  },
  {
    id: "Chatbot Messages",
    color: "hsl(226, 70%, 50%)",
    data: [
      {
        x: "01 May",
        y: 100,
      },
      {
        x: "02 May",
        y: 215,
      },
      {
        x: "03 May",
        y: 232,
      },
      {
        x: "04 May",
        y: 253,
      },
      {
        x: "05 May",
        y: 255,
      },
      {
        x: "06 May",
        y: 147,
      },
      {
        x: "07 May",
        y: 268,
      },
      {
        x: "08 May",
        y: 290,
      },
      {
        x: "09 May",
        y: 90,
      },
      {
        x: "10 May",
        y: 55,
      },
      {
        x: "11 May",
        y: 299,
      },
      {
        x: "12 May",
        y: 154,
      },
    ],
  },
  {
    id: "Business Messages",
    color: "hsl(206, 70%, 50%)",
    data: [
      {
        x: "01 May",
        y: 100,
      },
      {
        x: "02 May",
        y: 100,
      },
      {
        x: "03 May",
        y: 190,
      },
      {
        x: "04 May",
        y: 160,
      },
      {
        x: "05 May",
        y: 20,
      },
      {
        x: "06 May",
        y: 70,
      },
      {
        x: "07 May",
        y: 60,
      },
      {
        x: "08 May",
        y: 50,
      },
      {
        x: "09 May",
        y: 140,
      },
      {
        x: "10 May",
        y: 180,
      },
      {
        x: "11 May",
        y: 100,
      },
      {
        x: "12 May",
        y: 115,
      },
    ],
  },
];
