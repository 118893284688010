import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";

export const useChangeOptin = () => {
  const [loading, setLoading] = useState(false);

  const changeOptStatus = async (id, OptedIn) => {
    try {
      setLoading(true);

      // console.log("send", OptedIn);

      const res = await axios.post(`/api/optin/change/optedIn/${id}`, {
        OptedIn,
      });

      console.log(res.data);

      toast.success(res?.data?.message || "Success!");
    } catch (error) {
      toast.error(error.response?.data?.error || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, changeOptStatus };
};

export const useSetOptinSetting = () => {
  const [loading, setLoading] = useState(false);

  const setOptinSetting = async (data) => {
    try {
      setLoading(true);

      const res = await axios.post("/api/optin/setting/update", data);
      console.log(res.data);

      toast.success("Optin setting changed successfully!");
    } catch (error) {
      toast.error(error?.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, setOptinSetting };
};

// export const useGetOptinSetting = () => {
//   const [loading2, setLoading] = useState(false);
//   const [optinData, setOptinData] = useState({});
//   useEffect(() => {
//     const getOptingSetting = async () => {
//       setLoading(true);
//       try {
//         const res = await axios.get("/api/optin/setting");
//         console.log("dats:", res.data);

//         if (res.data) {
//           setOptinData(res.data);
//         }
//       } catch (error) {
//         toast.error(error?.response?.data?.error || error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     getOptingSetting();
//   }, []);

//   return { loading2, optinData };
// };
