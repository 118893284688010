import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { mockLineData as data } from "../../data/mockData";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

function Graph() {
  return (
    <>
      <div className="w-full mx-auto rounded-sm mt-4  h-72 ">
        {/* <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        xFormat=" >-"
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        yFormat=" >-.2f"
        curve="catmullRom"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'transportation',
            legendOffset: 36,
            legendPosition: 'middle',
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle',
            truncateTickAt: 0
        }}
        enablePoints={false}
        pointSize={5}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={1}
        pointBorderColor={{ from: 'serieColor' }}
        enablePointLabel={true}
        pointLabel="data.x"
        pointLabelYOffset={-20}
        enableArea={true}
        areaOpacity={0.15}
        enableSlices="x"
        enableTouchCrosshair={true}
        useMesh={true}
        debugMesh={true}
        legends={[]}
        animate={false}
        motionConfig={{
            mass: 95,
            tension: 184,
            friction: 28,
            clamp: false,
            precision: 0.01,
            velocity: 0
        }}
    /> */}

        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 50, bottom: 80, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          yFormat="3> 0,.112~"
          curve="cardinal"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 13,
            tickRotation: 0,
            legend: " Analytics Chat Of Conversations",
            legendOffset: 60,
            legendPosition: "middle",
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 11,
            tickRotation: 0,
            legend: "Coversation",
            legendOffset: -50,
            legendPosition: "middle",
            truncateTickAt: 100,
          }}
          colors={{ scheme: "set3" }}
          pointSize={5}
          pointColor={{ from: "color", modifiers: [] }}
          pointBorderWidth={1}
          pointBorderColor={{ from: "serieColor", modifiers: [] }}
          pointLabel="data.yFormatted"
          pointLabelYOffset={-4}
          enableArea={true}
          areaOpacity={0.6}
          enableSlices="x"
          enableTouchCrosshair={true}
          crosshairType="y"
          useMesh={true}
          debugMesh={true}
          legends={[
            {
              anchor: "top",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: -38,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 147,
              itemHeight: 33,
              itemOpacity: 0.75,
              symbolSize: 10,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          motionConfig="wobbly"
        />
      </div>
    </>
  );
}

export default Graph;
