import { BiStore } from "react-icons/bi";
import { FaShopify } from "react-icons/fa";
import { GiConfirmed } from "react-icons/gi";
import { IoLogoStackoverflow } from "react-icons/io5";
import { MdOutlineMessage, MdProductionQuantityLimits } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

function SidebarOfEcom({ Tab }) {
  const PathName = useLocation().pathname;

  return (
    <div className="w-64 sticky top-16 h-screen shadow bg-white px-5 mx-auto">
      <div className="py-5 text-center px-6 text-xl font-medium mt-2">
        Ecommerce
      </div>
      <div className="mt-4">
        <Link to={`${Tab.link1}`}>
          <div
            className={`mx-auto w-56 btn btn-sm  border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center active ${
              PathName.includes(Tab.link1) ? "bg-[#b9e5dc]" : "bg-[#EBF5F3]"
            }`}
          >
            <div className="text-xl">
              <BiStore />
            </div>
            <div className="font-medium ms-3 ">Store Setup</div>
          </div>
        </Link>

        <Link to={`${Tab.link2}`}>
          <div
            className={`mx-auto mt-3 w-56 btn btn-sm  border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center active ${
              PathName.includes(Tab.link2) ? "bg-[#b9e5dc]" : "bg-[#EBF5F3]"
            }`}
          >
            <div className="text-xl">
              <MdOutlineMessage />
            </div>
            <div className="font-medium ms-3 ">Setup Messages</div>
          </div>
        </Link>

        <Link to={`${Tab.link3}`}>
          <div
            className={`mx-auto mt-3 w-56 btn btn-sm  border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center active ${
              PathName.includes(Tab.link3) ? "bg-[#b9e5dc]" : "bg-[#EBF5F3]"
            }`}
          >
            <div className="text-xl">
              <FaShopify />
            </div>
            <div className="font-medium ms-3 ">Shopify Checkouts</div>
          </div>
        </Link>

        <Link to={`${Tab.link4}`}>
          <div
            className={`mx-auto mt-3 w-56 btn btn-sm  border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center active ${
              PathName.includes(Tab.link4) ? "bg-[#b9e5dc]" : "bg-[#EBF5F3]"
            }`}
          >
            <div className="text-xl">
              <MdProductionQuantityLimits />
            </div>
            <div className="font-medium ms-3 ">Abandoned Cart Flow</div>
          </div>
        </Link>

        <Link to={`${Tab.link5}`}>
          <div
            className={`mx-auto mt-3 w-56 btn btn-sm  border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center active ${
              PathName.includes(Tab.link5) ? "bg-[#b9e5dc]" : "bg-[#EBF5F3]"
            }`}
          >
            <div className="text-xl">
              <GiConfirmed />
            </div>
            <div className="font-medium ms-3 ">Confirmation Flow</div>
          </div>
        </Link>

        <Link to={`${Tab.link6}`}>
          <div
            className={`mx-auto mt-3 w-56 btn btn-sm  border-none shadow-none text-[#0A474C] hover:bg-[#c9e6e0] active:bg-[#EBF5F3] flex justify-start items-center active ${
              PathName.includes(Tab.link6) ? "bg-[#b9e5dc]" : "bg-[#EBF5F3]"
            }`}
          >
            <div className="text-xl">
              <IoLogoStackoverflow />
            </div>
            <div className="font-medium ms-3 ">Order Flow</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SidebarOfEcom;
