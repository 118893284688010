import React from "react";
import { FaClockRotateLeft } from "react-icons/fa6";

function ComingSoonPage() {
  return (
    <>
      <div className="w-full h-screen text-6xl flex justify-center items-center bg-[#EBF5F3] text-[#0A474C] animate-pulse ">
        <div className="me-4">Coming Soon</div>
        <FaClockRotateLeft />
      </div>
    </>
  );
}

export default ComingSoonPage;
