import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { UseAuthContext } from "../context/AuthContext";

const useLogOut = () => {
  const [loading, setLoading] = useState(false);
  const { setAuthUser } = UseAuthContext();

  const logOut = async () => {
    try {
      setLoading(true);

      const res = await axios.post("/api/auth/loguot", {});

      const data = res.data;

      localStorage.removeItem("authUser");

      setAuthUser(null);

      // toast.success(data.message);
      // toast.success(data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };
  return { loading, logOut };
};

export default useLogOut;
