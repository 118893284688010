// import React, { useState } from "react";
// import { IoSearchSharp } from "react-icons/io5";
// import useConversation from "../../zustand/useConversation";
// import useGetConversations from "../../hooks/useGetConversations";
// import toast from "react-hot-toast";

// const SearchInput = () => {
//   const [search, setSearch] = useState("");
//   const { setSelectedConversation } = useConversation();
//   const { conversations } = useGetConversations();

//   const hadleOnSubmit = (e) => {
//     e.preventDefault();
//     if (!search) return;
//     if (search.length < 3) {
//       return toast.error("Search turm must be at least 3 characters long");
//     }

//     const conversation = conversations.find((c) =>
//       c.fullName.toLowerCase().includes(search.toLowerCase())
//     );

//     console.log(conversation);

//     if (conversation) {
//       setSelectedConversation(conversation);
//       setSearch("");
//     } else toast.error("No such user found!");
//   };

//   return (
//     <form onSubmit={hadleOnSubmit} className="flex items-center gap-2">
//       <input
//         type="text"
//         placeholder="Search..."
//         className="input input-bordered rounded-full"
//         value={search}
//         onChange={(e) => {
//           setSearch(e.target.value);
//         }}
//       />
//       <button type="submit" className="btn btn-circle bg-sky-500 text-white">
//         <IoSearchSharp className="w-6 h-6 outline-none" />
//       </button>
//     </form>
//   );
// };

// export default SearchInput;

//STARTER CODE FOR SEARCHINPUT
import React from "react";
import { IoSearchSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

const SearchInput = ({ searchQuery, setSearchQuery }) => {
  return (
    <div className="w-[100%] mt-2">
      <label className="input h-10 text-sm border-none input-bordered bg-[#F0F0F0] flex items-center gap-2">
        <input
          type="text"
          className="grow placeholder:text-sm placeholder:font-light"
          placeholder="Search name or number"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
        <div
          className="bg-[#D6EDE9] text-[#0A474C] w-6 h-6 flex items-center justify-center text-sm hover:bg-[#baded8] rounded-full"
          onClick={() => {
            setSearchQuery("");
          }}
        >
          <IoMdClose />
        </div>
        <div className="bg-[#D6EDE9] text-[#0A474C] w-6 h-6 flex items-center justify-center text-sm hover:bg-[#baded8] rounded-full">
          <IoSearchSharp />
        </div>
      </label>
    </div>
  );
};

export default SearchInput;
